import * as React from "react";
import { View, StyledSystemProps } from "./Common";

type Props = {
  name: string;
} & StyledSystemProps;

const Icon = React.memo<Props>(({ name, ...props }) => (
  <View
    as="span"
    display="inline-block"
    className={`icon-${name}`}
    {...(props as any)}
  />
));

export default Icon;
