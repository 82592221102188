import React, { memo, useState, useEffect } from "react"
import { Column, View, Text } from "../ui"
import Playbtn from "./images/PLAY.png"
import useApp from "../providers/AppProvider"





type props = {
    image: string,
    episodeTitle: string,
    description: string,
    
    podcastName : string,
   

}

const PodcastCardDetail = memo<props>(({ image, episodeTitle,podcastName,  description }) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);
    const {setPlayerLink ,setPlayerArtist , setPlayerImage , setPlayerTitle} = useApp();

    const trimString = (text : string) => {
        let sentences = text.split("[.!?\\-]")
        return sentences[0] + sentences[1]
    }

    const TimeFormat = (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    useEffect(() => {
       
    },[])
    return (
    <Column  flex="1" width="300px" p="1rem">
            <Column
                ml="auto"
                mr="auto"
                height="290px"
                width="290px"
                style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
                onMouseEnter={() => mouseEnter(1)}
                onMouseLeave={() => mouseLeave()}
                cursor="pointer"
            >
                <View
                    style={{ backgroundColor: "rgba(0,0,0,.4)", transition: "ease-in .1s" }}
                    width="290px"
                    height="290px"
                    position="relative"
                ></View>
                   
            </Column>

            <Column p=".5rem" flex="1" >
                <Text m=".5px" as="h2" fontSize="2em" fontWeight="bold">{episodeTitle}</Text>
                <Text>{description}</Text>
               
            </Column>

        </Column>
    )

})



export default PodcastCardDetail