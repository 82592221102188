import React, { memo, useState } from "react"
import { Row, Text, Column, View } from "../ui"
import useApp from "../providers/AppProvider"
import Playbtn from "./images/PLAY.png"
type props = {
    image: string,
    podcastName: string,
    episodeTitle: string,
    description?: string,
    episode?: string,
    duration?: number,
    episodeLink: string,
}

const PodcastEpisodeCard = memo<props>(({ episodeTitle, image, episodeLink, podcastName, episode, duration, description }) => {
    const {  setPlayerLink , setPlayerArtist , setPlayerImage , setPlayerTitle , setPlayerWebRadio} = useApp();
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(0)

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)
    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    const TimeFormat = (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }


    return (
        <Row>
            <Column p="1rem">
                <View
                    cursor="pointer"
                    as="div"
                    style={{ backgroundSize: "cover", backgroundImage: `url(${image})` }}
                    width="200px"
                    height="200px"
                    onClick={() =>  { setPlayerLink(episodeLink) ; setPlayerWebRadio(""); setPlayerArtist(podcastName) ; setPlayerImage(image) ; setPlayerTitle(episodeTitle) ; window.scrollTo(0,0) }}
                    onMouseLeave={() => mouseLeave()}
                    onMouseEnter={() => mouseEnter(1)}
                >
                    {hovered && hoveredId === 1 ?
                        <View as="div"
                            width="100%"
                            height="100%"
                            style={{ backgroundColor: "rgba(0,0,0,.4)", transition: "ease-in .1s" }}
                            position="relative"
                            >
                               <View
                        style={{ transition: "ease-in .1s" }}
                        as="div"
                        height="30px"
                        width="30px"
                        m="auto"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                    >
                        <View
                            as="img"
                            src={Playbtn}
                            height="30px"
                            width="30px"
                            m="auto"
                            
                        ></View>
                    </View>

                            </View> :
                        <View as="div" width="100%" height="100%"></View>
                    }
                </View>
            </Column>
            <Column p="1rem">
                <Text m="0px" style={{color : "black"}} as="h2">{episodeTitle}</Text>
                {/* <Text m="0px" as="h2">{podcastName}</Text> */}
                <Row>
                    <Text m="0px" mt="1rem" as="p" fontWeight="300">Episode {episode}</Text>
                    <Text m="0px" mt="1rem" pl="1rem" as="p" fontWeight="300">{duration ? TimeFormat(duration) : null}</Text>
                </Row>
                <Text m="0px" mt="3px" as="p"> {description}</Text>

            </Column>
        </Row>
    )
})



export default PodcastEpisodeCard