import React, { memo, useState } from "react"
import { Column, Text, Row, View } from "../ui"

type props = {
    title : string ,
    date?: string,
    subject? : string
}

const SideBarCard =memo<props>(({title, date , subject}) => {
 const [hovered , setHovered] = useState(false)

    const decodeHtml = (html : string) =>  {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    return (
        <Column  p="1rem" onMouseEnter={() => {setHovered(true)}} onMouseLeave={() => {setHovered(false)}}>
            <Row>
               { hovered?  <Text m="0px"
                    as="h3"
                    fontWeight="600"
                     style={{ color: "#000000" }}>
                    {decodeHtml(title)}
            </Text> : 
            <Text m="0px"
            as="h3"
            fontWeight="600"
             style={{ color: "#616164" }}>
            {decodeHtml(title)}
    </Text>
            }
            </Row>
            <Row>
                <Column mr="8px">
                    <Text m="0px"
                     as="p" 
                     style={{ color: "#6C00FF" }}
                     fontWeight="bold"
                     >{subject}</Text>
                </Column>
                <Column>
                    <Text m="0px" as="p" fontWeight="200">{date}</Text>
                </Column>
               
            </Row>
            <Row pt="1rem">
            <View as="div" height="1px" style={{backgroundColor : "#DBDBDB"}} width="100%"></View>
            </Row>

        </Column>
    )
})




export default SideBarCard