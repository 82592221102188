import React, { createContext, memo, useContext, useState } from "react";
import moment, { Moment } from "moment"
import "moment/locale/fr"

type ContextProps = {
  example: string[];
  setExample: (value: string[]) => void;
  page: string;
  setPage : (value : string) => void;
  PlayerLink: string;
  setPlayerLink: (value : string) => void;
  PlayerImage: string ;
  setPlayerImage : (value : string) => void;
  PlayerTitle : string ;
  setPlayerTitle : (value : string) => void;
  PlayerArtist: string;
  setPlayerArtist : (value : string) => void;
  isOpen : boolean ;
  setIsOpen : (value : boolean) => void;
  date : Moment ;
  setDate : (value : Moment ) => void;
  lastSongs : any ;
  setLastSongs : (value : any) => void; 
  playerWebRadio : string;
  setPlayerWebRadio : (value : string) => void ;
  isPodcast: boolean ;
  setIsPodcast: (value : boolean) => void;
};

const Context = createContext<ContextProps>({} as any);

type Props = {
  children: React.ReactNode;
};

const AppProvider = memo<Props>(({ children }) => {
  moment.locale('fr')
  const [example, setExample] = useState<string[]>([]);
  const [page, setPage] = useState<string>("Home");
  const [PlayerLink , setPlayerLink] = useState<string>("https://www.radioking.com/play/lechantier-radio/182095")  ;
  const [PlayerImage , setPlayerImage] = useState<string>("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc")
  const[PlayerTitle , setPlayerTitle] = useState<string>("Le Chantier")
  const[PlayerArtist , setPlayerArtist] = useState<string>("Le Chantier")
  const[isOpen, setIsOpen] = useState<boolean>(false);
  const [date , setDate ] = useState(moment());
  const  [lastSongs , setLastSongs] = useState<any>([])
  const [playerWebRadio , setPlayerWebRadio] = useState<string>("");
  const [isPodcast, setIsPodcast] = useState<boolean>(false);

  const value = {
    example,
    setExample,
    page,
    setPage,
    PlayerLink,
    setPlayerLink,
    PlayerImage ,
    setPlayerImage ,
    PlayerTitle ,
    setPlayerTitle , 
    PlayerArtist , 
    setPlayerArtist,
    isOpen ,
    setIsOpen,
    date,
    setDate,
    lastSongs,
    setLastSongs,
    playerWebRadio,
    setPlayerWebRadio,
    isPodcast, 
    setIsPodcast
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
});

const useApp = () => useContext(Context);

export { AppProvider };

export default useApp;
