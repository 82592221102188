import React, { useEffect } from "react"
import { Column, Text, Row } from "../ui"
import TwitterTimeline from "../components/TwitterTimeline"
import WebRadioStation from "../components/WebRadioStation"
import GreenHr from "../components/GreenHr"
import SideBar from "../components/SideBar"
import useApp from "../providers/AppProvider"
import { useLocation } from "react-router-dom"
import nouveautesImage from '../components/images/web_radio_nouveautes.jpg';
import messeMinuitImage from '../components/images/web_radio_messe_minuit.jpg';
import nuitPeurImage from '../components/images/web_radio_nuit_peur.jpg';

const WebRadio = () => {
   
    const {setPage} = useApp()

   

    useEffect(() => {
       
    } ,[])
    return (
        <Column flex="1"  justifyContent="center">
            <Column width="100%" >
                <Row width="100%">
                    <Text as='h1' m="1.5rem" color="primary" fontWeight="bold">WEB RADIOS</Text>
                </Row>
                <Row flex="1">
                    <GreenHr />
                </Row>
            </Column>
            <Column flex="1">
                <WebRadioStation
                    playlist ="https://open.spotify.com/playlist/2v3AA5sx7FexIqoUTTG32M"
                    subtitle="Le Chantier"
                    link={"https://www.radioking.com/play/lechantier-lesnouveautesdumoment/373765"}
                    title="Les Nouveautés du moment"
                    image={nouveautesImage}
                    content="Le meilleur du moment, le moment du meilleur. Dans cette sélection constamment renouvelée, retrouvez uniquement des morceaux que votre primeur va vous envier. Un flux éclectique, du contenu exclusif (avant-premières, cartes blanches…), c'est ici que seront diffusés l'intégralité des maxis et albums coups de cœur du Chantier."
                />
                <WebRadioStation
                    playlist ="https://open.spotify.com/playlist/1FqDWq7i8HP04CnZmy4ngP"
                    subtitle="Le Chantier"
                    link={"https://www.radioking.com/play/lechantier-nuittumefaispeur/373756"}
                    title="Nuit tu me fais peur"
                    image={nuitPeurImage}
                    content="Laissez-vous porter par la lune. Et par la sélection toute en élégance et fluidité de notre indolent programmateur. Espace de libre expression singulier à la ligne musicale climatique, ne soyez pas surpris si vous rencontrez des créations sonores expérimentales ou des happenings non-identifiés de plus de 20 minutes. Tout est parfaitement sous contrôle !"
                />
                <WebRadioStation
                    playlist ="https://open.spotify.com/playlist/5Jsco0nDhPBCEzROzilGoJ"
                    subtitle="Le Chantier"
                    link={"https://www.radioking.com/play/lechantier-lamessedeminuit/373774"}
                    title="La messe de minuit"
                    image={messeMinuitImage}
                    content="Le meilleur de la musique solide ! Retrouvez ici une sélection inspirée de La messe de minuit, diffusée chaque jour de minuit à 1h sur Le Chantier. C'est le rendez-vous incontournable des musiques extrêmes et pas uniquement métal ! Une scène foisonnante, engagée et hyperactive... Comment aurions-nous pu nous en passer ?"
                     />
            </Column>
        </Column>
    )
}


export default WebRadio