import * as React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

type Props = {
    children: React.ReactNode;
    to: string;
}

const VioletLink = React.memo<Props>(({ children, to, ...props }) => {
    return (
        <StyledLink to={to} {...props}>
            {children}
        </StyledLink>
    );
});

const StyledLink = styled(Link)(
    {
        marginTop: '.5rem',
        fontFamily: 'Barlow Condensed',
        color: '#6C00FF',
        fontWeight: 'bold',
        textDecoration: 'none',
        fontSize: '18px',
        "&:hover": {
            color: '#616164'
        }
    }
)

export default VioletLink;