import React, { useEffect, useState, useRef } from "react"
import { Column, View } from "../ui"
import SocialsBar from "./SocialsBar"
import SideBarCard from "./SideBarCard"
import TwitterTimeline from "./TwitterTimeline"
import { Link } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser";
import Pub from "./Pub"



const SideBar = () => {
  const [news, setNews] = useState<any>();



  const stripHtml = (htmlString: string) => {
    return htmlString.replace(/<[^>]+>/g, '');
  }

  const display = () => {

    let feed: any = [];
    news.map((post: any) => {
      let parsedHTML: any = parse(post.content.rendered)
      // console.log(parsedHTML)
      let subject = stripHtml(parsedHTML[1].props.children.toUpperCase())
      let title = post.title.rendered
      let date = new Date(post.date).toLocaleDateString('fr-FR')
      feed.push(<Link key={uuidv4()} to={"/infos/" + post.slug} style={{ textDecoration: "none", color: "#000" }}> <SideBarCard title={title} key={post.id} subject={subject} date={date} /></Link>)
    })
    return feed.slice(0, 4);
  }
  const contentRef = useRef<HTMLDivElement>()
  //test avec un wordpress en local 
  useEffect(() => {
    fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=3")
      .then((response) => {
        return response.json();
      })
      .then((posts) => {
        setNews(posts);
      })

  }, [])


  const displayLoading = () => {

    return (
      <Column  >
        <View mt="10px" mb="10px" as="div" width='250px' height='80px' style={{ backgroundColor: "whitesmoke" }} />
        <View as="div" mt='5px' mb="5px" width="250px" height="2px" style={{ backgroundColor: "whitesmoke" }} />
        <View mt="10px" mb="10px" as="div" width='250px' height='80px' style={{ backgroundColor: "whitesmoke" }} />
        <View as="div" mt='5px' mb="5px" width="250px" height="2px" style={{ backgroundColor: "whitesmoke" }} />
        <View mt="10px" mb="10px" as="div" width='250px' height='80px' style={{ backgroundColor: "whitesmoke" }} />
        <View as="div" mt='5px' mb="5px" width="250px" height="2px" style={{ backgroundColor: "whitesmoke" }} />
        <View mt="10px" mb="10px" as="div" width='250px' height='80px' style={{ backgroundColor: "whitesmoke" }} />
      </Column>
    )
  }


  return (
    <Column ref={contentRef} flex="1" p="1rem" >

      <SocialsBar />
      <Column>
        <Pub />
      </Column>
      <Column >
        {
          news
            ?
            display()
            :
            displayLoading()
        }
      </Column>
      <Column flex="1">
        <TwitterTimeline height={800} />
      </Column>
    </Column>
  )
}




export default SideBar