import React, { memo, useState } from "react"
import { Link } from "react-router-dom";
import { Column, View, Text } from "../ui"
import { v4 as uuidv4 } from "uuid"
import VioletLink from "../ui/VioletLink";
import TitleCardLink from "../ui/TitleCardLink";
import VioletLinkTag from "../ui/VioletLinkTag";


const decodeHtml = (html: string) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

type props = {
    src: string,
    title: string,
    podcastTitle: string,
    description: string,
    date?: string,
    slug?: string,
    tag?: number,
    onClickTag?: () => void
}

const readMore = {
    color: "#6C00FF"
}

const readMoreHover = {
    color: "#6C00FF",
    fontWeight: "bold"
}

const NewsCard = memo<props>(({ src, title, date, podcastTitle, description, slug, tag, onClickTag }) => {
    return (
        <Column width="344px" p="0.5rem">
            <Column>
                <Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={"/infos/" + slug}>
                    <View
                        ml="auto"
                        mr="auto"
                        height="194px"
                        width="100%"
                        style={{ backgroundImage: `url(${src})`, backgroundSize: "cover", backgroundPosition: "center" }} >
                    </View>
                </Link>

            </Column>

            <Column pt=".5rem" >
                {
                    onClickTag
                    ?
                    <VioletLinkTag key={uuidv4()} handleClick={onClickTag}>
                        {decodeHtml(podcastTitle)}
                    </VioletLinkTag>
                    :
                    <VioletLinkTag key={uuidv4()} to={`/infos/?tag=${tag}`}>
                        {decodeHtml(podcastTitle)}
                    </VioletLinkTag>
                }
                {/* <Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={`/infos/?tag=${tag}`}>
                    <Text m=".5px" as="p" fontWeight="bold" style={{ color: "#6C00FF" }}>{decodeHtml(podcastTitle)}</Text>
                </Link> */}
                <TitleCardLink to={"/infos/" + slug}>
                    {decodeHtml(title)}
                </TitleCardLink>
                <Text as="p" m=".5px" mt=".5rem" mb=".5rem" color="black" fontWeight="200">{date}</Text>
                <Text>{decodeHtml(description)}</Text>

                <VioletLink to={"/infos/" + slug}>
                    Lire la suite
                </VioletLink>
            </Column>

        </Column >
    )

})



export default NewsCard