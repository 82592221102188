import React from "react"
import { Column, Row ,Text, View} from "../ui"
import Close from "./images/HamburgerClose.svg"
import useApp from "../providers/AppProvider";
import { Link } from "react-router-dom";
import SocialsBar from "./SocialsBar";

const MobileMenu = () => {
    
    const {setIsOpen , isOpen , PlayerLink , setPlayerArtist, setPlayerImage , setPlayerLink , setPlayerTitle} = useApp();
    
    return(
        <Column  flex="1" backgroundColor="secondary" width="80%" style={{position: "fixed" , top: 0 ,bottom:0 ,left:0  , zIndex:3  }}>
            <Row p="1rem">
                 <View as="img" m="0" onClick={() => {setIsOpen(!isOpen) ; }} src={Close} height="30px" width="30px"></View>
            </Row>
            {/* <Row p="1rem">
                <Link onClick={() => { setIsOpen(!isOpen)}} style={{textDecoration : "none"}} to="/programmes">
                <Text as="h3" m="0" fontWeight="bold" color="white">Programmes</Text>
                </Link>
            </Row> */}
            <Row p="1rem">
                <Link onClick={() => { setIsOpen(!isOpen)}} to="/" style={{textDecoration : "none"}}>
                <Text as="h3" m="0" fontWeight="bold" color="white">Accueil</Text>
                </Link>
            </Row>
            <Row p="1rem">
                <Link onClick={() => { setIsOpen(!isOpen)}} to="/programmes" style={{textDecoration : "none"}}>
                <Text as="h3" m="0" fontWeight="bold" color="white">Réécoutes</Text>
                </Link>
            </Row>
            {/* <Row p="1rem">
                <Link onClick={() => { setIsOpen(!isOpen)}} to="/podcasts" style={{textDecoration : "none"}}>
                <Text as="h3" m="0" fontWeight="bold" color="white">Podcasts</Text>
                </Link>
            </Row> */}
            <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/infos" style={{textDecoration : "none"}}>
                <Text as="h3" m="0" fontWeight="bold" color="white">Infos</Text>
                </Link>
            </Row>
            <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/webradios" style={{textDecoration : "none"}}>
                <Text  as="h3" m="0" fontWeight="bold" color="white">Web radios</Text>
                </Link>
            </Row>
            <Row width="60%" mt=".5rem" mb=".5rem" ml="auto" mr="auto">
                <View as="div" backgroundColor="#616164" width="100%" height="1px"></View>
            </Row>
            <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/titresdiffuses" style={{textDecoration : "none"}}>
                <Text fontWeight="300"  color="white">Titres Diffusés</Text>
                </Link>
            </Row>
            <Row p="1rem">
                <Link onClick={() => { setIsOpen(!isOpen)}} to="/apropos" style={{textDecoration : "none"}}>
                    <Text fontWeight="300"  color="white">Qui sommes nous ?</Text>
                </Link>
            </Row>

            <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/contact" style={{textDecoration : "none"}}>
                <Text fontWeight="300"  color="white">Contact</Text>
                </Link>
            </Row>
            {/* <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/partenaires" style={{textDecoration : "none"}}>
                <Text fontWeight="300"  color="white">Partenaires</Text>
                </Link>
            </Row> */}
            <Row p="1rem">
            <Link onClick={() => { setIsOpen(!isOpen)}} to="/legal" style={{textDecoration : "none"}}>
                <Text fontWeight="300" color="white">Mentions légales</Text>
                </Link>
            </Row>
            <Row width="60%" mt=".5rem" mb=".5rem" ml="auto" mr="auto">
                <View as="div" backgroundColor="#616164" width="100%" height="1px"></View>
            </Row>
            <SocialsBar />
        </Column>
    )
}



export default MobileMenu