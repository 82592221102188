import * as React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { View } from "./Common";

type Props = {
    children: React.ReactNode;
    to?: string;
    handleClick?: () => void;
}

const VioletLinkTag = React.memo<Props>(({ children, to, handleClick, ...props }) => {
    return (
        to 
        ?
        <StyledLink to={to} {...props}>
            {children}
        </StyledLink>
        :
        <StyledTag onClick={handleClick} {...props}>
            {children}
        </StyledTag>
    );
});

const StyledLink = styled(Link)(
    {
        marginTop: '.5rem',
        color: '#6C00FF',
        textDecoration: 'none',
        fontWeight: 'bold',
        "&:hover": {
            color: '#616164'
        }
    }
);

const StyledTag = styled(View)(
    {
        marginTop: '.5rem',
        color: '#6C00FF',
        textDecoration: 'none',
        fontWeight: 'bold',
        "&:hover": {
            color: '#616164',
            cursor: 'pointer'
        }
    }
)

export default VioletLinkTag;