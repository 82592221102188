import React, { useRef, useState, FormEvent, useEffect } from "react"
import { Row, Text, Column, View } from "../ui"
import { Link, BrowserRouter as Router, useLocation, useHistory } from "react-router-dom"
import LogoWhite from "./images/LogoWhite.png"
import LogoPrimary from "./images/LogoPrimary.png"
import SearchIcon from "./images/SearchIcon.png"
import useApp from "../providers/AppProvider"
import InfosMobile from "../scenes/InfoMobile"

const Navbar = () => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);
    const history = useHistory();
    const [value, setValue] = useState<string>("")
    const { page, setPage } = useApp();

   let location = useLocation();

   useEffect(() => {
      switch(location.pathname){
          case "/" : setPage("Home"); break;
          case "/podcasts" : setPage("Podcasts"); break;
          case '/infos' : setPage("Infos") ; break;
          case '/webradios' : setPage("Radios") ; break;
          default : setPage("");break;
      }
      if(location.pathname.includes("infos")){
          setPage("Infos");
      }
      if(location.pathname.includes("podcasts")){
          setPage("Podcasts")
      }

   } , [location])

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget?.value);
    }


    return (
        <Row bg="secondary"height="80px" alignItems="center">

            <Row justifyContent="flex-end" flex="1">

                {/* <Column p="1rem">
                        <Link style={{textDecoration: "none"}} to="/programmes">
                            {(hovered && hoveredId === 1) || page === "Programmes" ?
                                <Text as="p" color="primary" onClick={() => setPage('Programmes')} onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()} >Programmes</Text>
                                :
                                <Text as="p" color="white" onClick={() => setPage('Programmes')} onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()} >Programmes</Text>
                            }
                            </Link>

                </Column> */}
                {(hovered && hoveredId === 1) || page === "Programmes" ?
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage("Programmes")} to="/programmes">

                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="primary"  >Réécoutes</Text>
                        </Link>
                    </Column>
                    :
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage("Programmes")} to="/programmes">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="white"  >Réécoutes</Text>
                        </Link>
                    </Column>
                }


                {/* {(hovered && hoveredId === 2) || page === "Podcasts" ?
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(2)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage("Podcasts")} to="/podcasts">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="primary"  >Podcasts</Text>
                        </Link>
                    </Column>

                    :
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(2)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage("Podcasts")} to="/podcasts">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="white"  >Podcasts</Text>
                        </Link>
                    </Column>
                } */}


                {(hovered && hoveredId === 3) || page === "Infos" ?
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(3)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage('Infos')} to="/infos">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="primary"  >Infos</Text>
                        </Link>
                    </Column>
                    :
                    <Column p="1rem" cursor="pointer" onMouseEnter={() => mouseEnter(3)} onMouseLeave={() => mouseLeave()}>
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage('Infos')} to="/infos">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="white"  >Infos</Text>
                        </Link>
                    </Column>
                }


                {(hovered && hoveredId === 4) || page === "Radios" ?
                    <Column p="1rem" onMouseEnter={() => mouseEnter(4)} onMouseLeave={() => mouseLeave()} cursor="pointer">
                        <Link style={{ textDecoration: "none" }} onClick={() => setPage('Radios')} to="/webradios">
                            <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="primary" >Web Radios</Text>
                        </Link>
                    </Column>

                    :
                    <Column p="1rem" onMouseEnter={() => mouseEnter(4)} onMouseLeave={() => mouseLeave()} cursor="pointer">
                    <Link style={{ textDecoration: "none" }} onClick={() => setPage('Radios')} to="/webradios">
                        <Text style={{ fontFamily: 'Barlow Semi Condensed', fontWeight: 500, fontSize: 20 }} as="p" color="white" >Web Radios</Text>
                    </Link>
                </Column>
                }

            </Row>
            <Row flex="1" justifyContent="center">
                {/* <Column pl='3rem' pr="3rem" flex="1" onClick={() => setPage("Home")} verticalAlign="middle"> */}
                    <Link to="/">

                        {hovered && hoveredId === 5 ?
                            <View

                                as="img"
                                src={LogoPrimary}
                                width="150px"
                                p="1rem"
                                onMouseEnter={() => mouseEnter(5)} onMouseLeave={() => mouseLeave()}

                            ></View>

                            :
                            <View
                                as="img"
                                src={LogoWhite}
                                width="150px"
                                p="1rem"
                                onMouseEnter={() => mouseEnter(5)} onMouseLeave={() => mouseLeave()}

                            ></View>
                        }
                    </Link>
                {/* </Column> */}
            </Row>
            <Row flex="1" p="1rem">
                <View as="form" onSubmit={(e: any) => { history.push(`/search/${value}`); setValue(''); e.preventDefault() }}>
                    <Row flex="1" alignItems={'center'} >

                        <Column alignItems="center" p="1rem">
                            <View as="input"
                                type="text"
                                placeholder="Rechercher"
                                color="primary"
                                backgroundColor="white"
                                borderStyle="none"
                                borderRadius="5px"
                                onChange={(e: FormEvent<HTMLInputElement>) => handleChange(e)}
                                value={value}
                                textAlign="right"
                                style={{ padding: '6px 10px', fontFamily: 'Barlow Condensed', fontSize: 17 }}
                            ></View>
                        </Column>
                        <Column>
                            <View as="img"
                                onClick={() => {
                                    if (value !== undefined) {
                                        if (value !== "") {
                                            history.push(`/search/${value}`);
                                        }
                                    }
                                    
                                    setValue('');
                                }}
                                src={SearchIcon}
                                width="20px"
                            >

                            </View>
                            <View as="input" type="submit" style={{ display: "none" }} ></View>
                        </Column>

                    </Row>
                </View>
            </Row>

        </Row>
    )
}









export default Navbar