import React, { useState } from "react"
import { Row, Column, Text, View } from "../ui"

import FacebookGrey from "./images/FacebookGrey.png"
import InstagramGrey from "./images/InstagramGrey.png"
import TwitterGrey from "./images/TwitterGrey.png"
import SpotifyGrey from "./images/SpotifyGrey.png"
import YoutubeGrey from "./images/YoutubeGrey.png"
import FacebookGreen from "./images/FacebookGreen.png"
import InstagramGreen from "./images/InstagramGreen.png"
import TwitterGreen from "./images/TwitterGreen.png"
import SpotifyGreen from "./images/SpotifyGreen.png"
import YoutubeGreen from "./images/YoutubeGreen.png"





const SocialsBar = () => {

    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    
    return (

        <Column pt="1rem">
            <Row >
                <Row  m="auto" >
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 1 ? <View as="a" m="auto" href="https://www.facebook.com/lechanchanlechantier" target="_blank"><View
                            as="img"
                            src={FacebookGreen}
                            height="15px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(1)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.facebook.com/lechanchanlechantier">
                                <View
                                    as="img"
                                    src={FacebookGrey}
                                    height="15px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(1)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 2 ? <View as='a' m="auto" href="https://www.instagram.com/lechantier.radio/" target="_blank"><View
                            as="img"
                            src={InstagramGreen}
                            height="15px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(2)}
                            onMouseLeave={() => mouseLeave()}
                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.instagram.com/lechantier.radio/" target="_blank">
                                <View
                                    as="img"
                                    src={InstagramGrey}
                                    height="15px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(2)}
                                    onMouseLeave={() => mouseLeave()}
                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 3 ? <View as='a' m="auto" href="https://twitter.com/le_chantier" target="_blank"><View
                            as="img"
                            src={TwitterGreen}
                            height="15px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(3)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View> </View> :
                            <View as='a' m="auto" href="https://twitter.com/le_chantier" target="_blank">
                                <View
                                    as="img"
                                    src={TwitterGrey}
                                    height="15px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(3)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 4 ? <View as='a' m="auto" href="https://open.spotify.com/user/knxt3628l2t3m24usem3fil1l?si=E_R9F5yuRBeNqd9WIMTJjw" target="_blank"><View
                            as="img"
                            src={SpotifyGreen}
                            height="15px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(4)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View> </View> :
                            <View as='a' m="auto" href="https://open.spotify.com/user/knxt3628l2t3m24usem3fil1l?si=E_R9F5yuRBeNqd9WIMTJjw">
                                <View
                                    as="img"
                                    src={SpotifyGrey}
                                    height="15px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(4)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>
                        }
                    </Column>
                    <Column flex="1" p="1rem">

                        {hovered && hoveredId === 5 ? <View as='a' m="auto" href="https://www.youtube.com/channel/UCnH5Q4WhXKkMpz7EZNDnXEA" target="_blank"><View
                            as="img"
                            src={YoutubeGreen}
                            height="15px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(5)}
                            onMouseLeave={() => mouseLeave()}
                            
                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.youtube.com/channel/UCnH5Q4WhXKkMpz7EZNDnXEA" target="_blank">
                                <View
                                    as="img"
                                    src={YoutubeGrey}
                                    height="15px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(5)}
                                    onMouseLeave={() => mouseLeave()}
                                   
                                >
                                </View></View>}

                    </Column>
                </Row>
            </Row>
        </Column >
    )




}









export default SocialsBar