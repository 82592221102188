import React from "react"
import { Column, View, Row } from "../ui"
import GreenHr from "./GreenHr"



const LoadingCardRow = () => {
return (
    <Column>
    <Row  width="800px">
                        
                            <Column p="1rem">
                                <View
                                    as="div"
                                    style={{backgroundColor : "whitesmoke"}}
                                    width="300px"
                                    height="300px"

                                ></View>
                            </Column>
                       
                        <Column p="1rem" width="400px">
                            
                               <View as="div" mb="10px" ml="10px" mr="10px" height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                           
                            {/* <Row>
                                <Text as="h4" m="0px" fontStyle="italic">
                                    {subtitle}
                                </Text>
                            </Row> */}
                            
                            <View as="div" m="10px" height='200px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                          
                                
                                <View as="div" m="10px" height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                <View as="div" m="10px" height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                

                        </Column>

                    </Row>
                    <GreenHr />
                    </Column>
)
}

export default LoadingCardRow