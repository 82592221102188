import React, { useState, useEffect } from "react";
import { Column, Text, Row, View } from "../ui";
import PodcastCard from "../components/PodcastCard";
import LastEvents from "../components/LastEvents";
import { v4 as uuidv4 } from "uuid"
import moment from "moment";
import { Link } from "react-router-dom";
import NewsCard from "../components/NewsCard";
import Spinner from "react-spinner-material";
import parse from "html-react-parser"
import LoadingCard from "../components/LoadingCard";



const HomeMobile = () => {
  const stripHtml = (htmlString: string) => {
    return htmlString.replace(/<[^>]+>/g, '');
  }

  const [podcast, setPodcast] = useState<any>();
  const [news , setNews] = useState<any>();
  const [hovered, setHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(0);


  const mouseEnter = (id: number) => {
    setHovered(true);
    setHoveredId(id)

  }

  const mouseLeave = () => {
    setHovered(false);
    setHoveredId(0)
  }

  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 0.5)) + min;
  }

  const display  = () => {
    let feed: any = [];
    news.map((article: any) => {
      let parsedHTML : any = parse(article.content.rendered);
      let srcUrl : string = "" ; 
      for(let i = 0 ; i < parsedHTML.length - 1 ; i++){
        if(parsedHTML[i].props){
          if(parsedHTML[i].props.children.type === "img"){
            if(srcUrl === ""){
            srcUrl = parsedHTML[i].props.children.props.src ; 
            }
  
  
          }
        }
      }
      if(srcUrl === ""){
        srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
      }
      feed.push(<Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={"/infos/" + article.slug}>
        <NewsCard src={srcUrl}
          title={article.title.rendered}
          podcastTitle={stripHtml(parsedHTML[1].props.children).toUpperCase()}
          description={stripHtml(article.excerpt.rendered).replace('[&hellip;]' , "...").replace(parsedHTML[1].props.children , "")}
          date={moment(article.date).format('ll')}
          slug={article.slug}
          tag={article.tags && article.tags.length > 0 ? article.tags[0] : null}
        />
      </Link>)
    });
    return feed.slice(0,3)
  }

  useEffect(() => {
    const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
    fetch(("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=2"))
      .then((response) => { return response.json() })
      .then((data) => {
        let allPodcasts: any = [];
        data.map((pod: any) => {
          allPodcasts.push({ id: pod.id, link: stripHtml(pod.content.rendered) });
        })
        allPodcasts = allPodcasts.slice(0, 4);

        let podcastsToDisplay:Array<any> = [];

        allPodcasts.map((pod: any, index: number) => {
          fetch(ApiString + pod.link).then((response) => {
            return response.json() 
          })
          .then((data) => { 
              if (data.items && data.items.length > 0){
                podcastsToDisplay.push({ id: pod.id, ep: data.items[0], feed: data.feed });
              }
          })
          .then(() => {
            if (podcastsToDisplay.length === allPodcasts.length) {
              setPodcast(podcastsToDisplay)
            }
          })
        })


      })

      fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=3")
      .then((response) => {return response.json()})
      .then((data) => setNews(data))

  }, [])



  const displayRandomPodcast = () => {
    let array: any = [];
    let display: any = [];
    console.log('display random', podcast)
    podcast.map((pod: any) => {
      array.push({id : pod.id ,  episode: pod.ep, podcast: pod.feed })
    })
    
    const sortedArray = array.sort((a: any, b: any) => { moment(a.episode.pubDate).diff(moment(b.episode.pubDate)) })
    display = sortedArray.map((ep: any) => {
      return (
        <PodcastCard
          episodeLink={ep.episode.enclosure.link}
          episodeTitle={ep.episode.title}
          image={ep.episode.thumbnail}
          description={stripHtml(ep.episode.description).substr(0, 200)}
          podcastName={ep.podcast.title}
          duration={ep.episode.enclosure.duration}
          redirect={ep.id}

        />
      )

    })
    // console.log(display.slice(0, 4))
    return display.slice(0, 4);
  }

  const displayLoading = (count : number)=> {
    let feed : any = []
    for(let i = 0 ; i < 10 ; i++){
        feed.push(<LoadingCard />)
    }
    return feed.slice(0, count)
  
  }

  return (
    <Column>
      <Text as="h1" m="1.5rem">INFOS</Text>
      {
        news 
        ? 
        display() 
        : 
        displayLoading(5)
      }
      <Row justifyContent="center">
                    <Link to="/infos" style={{ textDecoration: "none", color: "black" }}>
                     
                        <button 
                          onClick={() => { }}
                          style={{ backgroundColor: "#5DCD69", borderStyle: "none", borderRadius: 5, width: "auto", padding: "1rem", cursor: "pointer" }} >
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16 , fontWeight : "bold" }} color="white">Voir toutes les infos</Text>
                        </button> 
                      
                    </Link>


                  </Row>
      <Text as="h1" m="1.5rem">PODCASTS</Text>
      {
        podcast 
        ?  
        displayRandomPodcast()
        : 
        displayLoading(4)
      }
       <Row justifyContent="center">
                    <Link to="/podcasts" style={{ textDecoration: "none", color: "black" }}>
                        <button
                          onClick={() => { }}  
                          style={{ backgroundColor: "#5DCD69", borderStyle: "none", borderRadius: 5, width: "auto", padding: "1rem", cursor: "pointer" }}>
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16  , fontWeight : "bold"}} color="white">Voir tous les podcasts</Text>
                        </button>
                    </Link>


                  </Row>
    </Column>
  );
};

export default HomeMobile;
