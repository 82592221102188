import React, { memo, useEffect, useState } from "react"
import {Text, Row, Column, View } from "../ui";
import Spinner from "react-spinner-material";
import axios from "axios";
import GreenHr from "../components/GreenHr";
import parse from "html-react-parser";

type props = {
    postId: Number
}

const ContentPage = memo<props>(({ postId }) => {
    const [title , setTitle ] = useState<any>(null);
    const [post , setPost ] = useState<any>(null);

    const decodeHtml = (html : string) =>  {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    const displayBr = (array : any) =>{
       return array.map((el : any) => {
        //    console.log('DISPLAY BR TYPE', typeof(el));
          if (typeof(el) === "string") {
              return el
          }
          else if (typeof(el) === "object" && el.type === "a") {
            return <View style={{ color: '#5DCD69', fontWeight: 'bold' }} as="a" href={el.props.href} target="_blank">{el.props.children}</View>
          }
          else {
             return <br/>
          }
        })
    }

    const displayParagraph = (array : any) => {
        let feed : any = [] ; 
        for(let i = 0 ; i < array.length-1 ; i++){
            if(array[i].props){
                // console.log('CHILDREN TYPE', typeof (array[i].props.children));
                if(array[i].props.children.type === "img"){
                    feed.push(
                        <Row flex="1" justifyContent="center">
                            <View 
                                as="div" 
                                style={{ width: '100%', height: "50vw", maxHeight: "400px", backgroundImage : `url(${array[i].props.children.props.src})`, backgroundSize : "cover" , backgroundRepeat : "no-repeat" , backgroundPosition : "center" }}>
                            </View>
                        </Row>
                    )
                }
                else
                {
                    feed.push(
                        <Text  as={array[i].type}>
                            {
                                array[i].props.children.type === "em" || array[i].props.children.type === "strong" 
                                ? 
                                array[i].props.children 
                                : 
                                Array.isArray(array[i].props.children)
                                ? 
                                <Text as={array[i].type} >{displayBr(array[i].props.children)}</Text>
                                :
                                decodeHtml(array[i].props.children)
                            }
                        </Text>
                    )
                }
            
        }
            else{
            
            }
        }
        return feed;
    
    }

    const fecthPost = async () => {
        const wpPostUrl = `https://wp.lechantier.radio/wp-json/wp/v2/posts/${postId}`;
        const response = await axios.get(wpPostUrl);
        setTitle(response.data.title.rendered)
        let postContent = response.data.content.rendered;
        setPost(parse(postContent));
    }

    useEffect(() => {  
        fecthPost();   
    } , []);

    return(
        <Column width="100%" justifyContent="flex-start" alignItems='flex-start'>
            {
                post
                ? 
                <>
                <Column width="100%" >
                    <Row width="100%">
                        <Text as='h1' m="1.5rem" color="primary" fontWeight="bold" style={{ textTransform: "uppercase" }}>{title}</Text>
                    </Row>
                    <Row flex="1">
                        <GreenHr />
                    </Row>
                </Column>
                {displayParagraph(post)}
                </>
                : 
                <Row flex="1" p="3rem" justifyContent="center"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Row>
            }
        </Column>
    )
});


export default ContentPage;