import React from 'react';
import { Column, Row, Text, View } from './Common';
import playhover from "../components/images/PlayGrayHover.png"
// import play from "./images/PlayGray.png"

type Props = {
    link: any;
    onPlayerClicked: () => void;
}

const MediaLink = React.memo<Props>(({ link, onPlayerClicked }) => {
    return link && (
        <Row width="100%" flexWrap="wrap" alignItems="center">
            <Column height="100%" justifyContent="center" alignItems="center">
                <View cursor="pointer" as="img" src={playhover} width="50px" height="50px" onClick={onPlayerClicked}></View>
            </Column>
            <Column pl="1rem" justifyContent="center" alignItems="center">
                <Text as="p" cursor="pointer" style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500,  width: "100%", flexWrap: "wrap" }} onClick={onPlayerClicked}>{link.audio_link_label}</Text>
            </Column>
        </Row>
    );
});

export default MediaLink;