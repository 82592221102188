import React, { useEffect, useState } from "react"
import { Row, Column ,Text, View } from "../ui"
import ProgrammeMenu from "../components/ProgrammeMenu"
import ProgrammeSearch from "../components/ProgrammeSearch"
import Emissions from "./Emissions"
import Programmation from "./Programmation"

const Programme = () => {
    const [selectedComponent, setSelectedComponent] = useState<any>(<Programmation />);
    const [selectedItem, setSelectedItem] = useState<string>('programmation');

    useEffect(() => {
        console.log('SELECTED ITEM CHANGED', selectedItem);
    }, [selectedItem]);

    return (
        <Column justifyContent="start" alignItems="start" flex={1} mt={"20px"} mb={"20px"}>
            <ProgrammeMenu 
                showEmissions={() => { setSelectedComponent(<Emissions />); setSelectedItem('emissions'); } } 
                showProgrammation={() => { setSelectedComponent(<Programmation />); setSelectedItem('programmation') } } 
                selected={selectedItem}
            />
            {selectedComponent}
        </Column>
    )
}



export default Programme