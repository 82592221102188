import * as React from "react";
import Spinner from "react-spinner-material";
import { colors } from "./theme";

type Props = {
  color?: string;
  size?: number;
};

const Loader = React.memo<Props>(({ color = colors.primary, size = 130 }) => (
  <Spinner
    size={size}
    spinnerColor={color}
    spinnerWidth={size / 4}
    visible
    color="red"
    radius="60°%"
    stroke="test"
  />
));

export default Loader;
