import React, { memo, useEffect, useState } from "react"
import { Row, Text  , Column} from "../ui"
import { Link } from "react-router-dom";


type props = {
    data: any


}

const decodeHtml = (html : string) =>  {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const SearchResultCard = memo<props>(({ data }) => {
    const [podcast, setPodcast] = useState<any>(null);
    const [article, setArticle] = useState<any>(null);
    const stripHtml = (s: string) => {
        return s.replace(/(<([^>]+)>)/ig, "");
    }

    useEffect(() => {
        const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
        if ( stripHtml(data.content.rendered).match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/) && stripHtml(data.content.rendered).includes("ausha")) {
            fetch(ApiString + stripHtml(data.content.rendered))
                .then((response) => { return response.json() })
                .then((ans) => { setPodcast({ id: data.id, feed: ans.feed }) })
        }
        else {
            setArticle({ id: data.slug, feed: data })

        }
    }, [])
    return (
    <Row p="1rem"><Row p="1rem"  style={{backgroundColor : "whitesmoke"}} width="100%" m="auto">
        
        {podcast ?
        
            <Link style={{ textDecoration: "none", color: "black" }} to={`/podcasts/${podcast.id}`}>
                <Column>
                <Row>
                    <Text as="h3" color="primary">{decodeHtml(podcast.feed.title)}</Text>
                    <Text pl="1rem" as="h3" style={{color:"#6C00FF"}}>Podcast</Text>
                    </Row>
                    <Text textAlign="justify">{decodeHtml(podcast.feed.description)}</Text>
                    <br />
                    <Text style={{color : "#6C00FF"}}>Voir plus</Text>
                </Column>
            </Link>
            :
            article ?
                <Link style={{ textDecoration: "none", color: "black" }} to={`/infos/${article.id}`}>
                    <Column>
                    <Row >
                        <Text as="h3" color="primary">{decodeHtml(article.feed.title.rendered)}</Text>
                        <Text pl="1rem" as="h3" style={{color:"#6C00FF"}}>Article</Text>
                        </Row>
                        <Text textAlign="justify">{decodeHtml(stripHtml(article.feed.excerpt.rendered).replace("[&hellip;]" , "..."))}</Text>
                        <br />
                        <Text style={{color : "#6C00FF"}}>Voir plus</Text>
                    </Column>
                </Link>
                :
                <></>}

    </Row>
    </Row>)

})

export default SearchResultCard