import React, { useState, useEffect, memo } from "react"
import { useLocation } from 'react-router-dom'
import { Text, Column, Row } from "../ui"
import NewsCard from "../components/NewsCard"
import GreenHr from "../components/GreenHr"
import SideBar from "../components/SideBar"
import { Link } from "react-router-dom"
import moment from "moment"
import Spinner from "react-spinner-material"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"
import useApp from "../providers/AppProvider"
import MenuItem from "../components/MenuItem"
import LoadingCard from "../components/LoadingCard"
import Pagination from "@material-ui/lab/Pagination"
import ScrollToTop from "../components/ScrollToTop"

const displayLoading = () => {
    let feed: any = []
    for (let i = 0; i < 10; i++) {
        feed.push(<LoadingCard />)
    }
    return feed

}

const Infos = memo(() => {
    const params = new URLSearchParams(useLocation().search);
    const [news, setNews] = useState<any>()
    const [tags, setTags] = useState<any>()
    const [currentTag, setCurrentTag] = useState<null | number | string>(params.get('tag') ? params.get('tag') : null)
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState<number>();
    const [pageNb, setPageNb] = useState<number | null>();
    const { setPage } = useApp();

    const stripHtml = (htmlString: string) => {
        console.log('STRIP HTML', htmlString)
        return htmlString.replace(/<[^>]+>/g, '');
    }

    const display = () => {
        let feed: any = [];
        news.map((article: any) => {
            let parsedHTML: any = parse(article.content.rendered);
            let srcUrl: string = "";
            for (let i = 0; i < parsedHTML.length - 1; i++) {
                if (parsedHTML[i].props) {
                    if (parsedHTML[i].props.children.type === "img") {
                        if (srcUrl === "") {
                            srcUrl = parsedHTML[i].props.children.props.src;
                        }


                    }
                }
            }
            if (srcUrl === "") {
                srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
            }
            feed.push(
                <NewsCard src={srcUrl}
                    title={article.title.rendered}
                    podcastTitle={stripHtml(parsedHTML[1].props.children).toUpperCase()}
                    description={stripHtml(article.excerpt.rendered).replace('[&hellip;]', "...").replace(parsedHTML[1].props.children, "")}
                    date={moment(article.date).format('ll')}
                    slug={article.slug}
                    tag={article.tags && article.tags.length > 0 ? article.tags[0] : null}
                    onClickTag={article.tags && article.tags.length > 0 ? () => { setCurrentTag(article.tags[0]); setPageNb(null); setIsLoading(true) } : () => {}}
                />
                )
        });
        return feed
    }


    let feed;
    useEffect(() => {
        if (currentTag) {
            fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?per_page=100&tags=" + currentTag)
                .then((response) => { return response.json() })
                .then((data) => { 
                    setPageCount(data.length / 6 === 0 ? 1 : Math.floor((data.length / 6) + (data.length % 6 > 0 ? 1 : 0))); setPageNb(1) })
        }
        else {
            fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?per_page=100&categories=3")
                .then((response) => { return response.json() })
                .then((data) => { setPageCount(data.length / 6 === 0 ? 1 : Math.floor((data.length / 6) + (data.length % 6 > 0 ? 1 : 0))); setPageNb(1) })
        }
        // fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?per_page=100&categories=3")
        //     .then((response) => { return response.json() })
        //     .then((data) => { setPageCount(data.length / 6 === 0 ? 1 : Math.floor((data.length / 6) + (data.length % 6 > 0 ? 1 : 0))); setPageNb(1) })
        fetch('https://wp.lechantier.radio/wp-json/wp/v2/tags')
            .then((response) => { return response.json() })
            .then((data) => { setTags(data) })

    }, [])

    useEffect(() => {
        // console.log('PAGE NB', pageCount, pageNb);
        if (currentTag && pageNb) {
            fetch('https://wp.lechantier.radio/wp-json/wp/v2/posts?tags=' + currentTag + '&per_page=6&page=' + pageNb)
                .then((res) => res.json())
                .then((data) => { setNews(data); setIsLoading(false) });

        } else if (pageNb && pageNb !== null) {
            fetch('https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=3&per_page=6&page=' + pageNb)
                .then((res) => res.json())
                .then((data) => { setNews(data); setIsLoading(false) });

        } else {
            setNews(null);
        }
    }, [pageNb])


    useEffect(() => {
        console.log("current tag : " + currentTag)
        if (currentTag) {
            fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?per_page=100&tags=" + currentTag)
                .then((response) => { return response.json() })
                .then((data) => { 
                    setPageCount(data.length / 6 === 0 ? 1 : Math.floor((data.length / 6) + (data.length % 6 > 0 ? 1 : 0))); setPageNb(1) })
        }
        else {
            fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?per_page=100&categories=3")
                .then((response) => { return response.json() })
                .then((data) => { setPageCount(data.length / 6 === 0 ? 1 : Math.floor((data.length / 6) + (data.length % 6 > 0 ? 1 : 0))); setPageNb(1) })
        }

    }, [currentTag])


    return (
        <Row width="100%" justifyContent="center">
            <Column width="100%">
                <Column width="100%" >
                    <Row width="100%">
                        <Text as='h1' m="1.5rem" color="primary" fontWeight="bold">INFOS</Text>
                    </Row>
                    <Row flex="1">
                        <GreenHr />
                    </Row>
                </Column>
                <Row height='100px' maxWidth="90%" flexWrap='wrap'>

                    {
                        tags && currentTag == null ?
                            <MenuItem type='active' key={tags[0].id} onClick={() => { setCurrentTag(null); setPageNb(null); setIsLoading(true) }} title={"Tous les articles"} />
                            :
                            <MenuItem type='none' key={tags ? 1 : 2} onClick={() => { setCurrentTag(null); setPageNb(null); setIsLoading(true) }} title={"Tous les articles"} />
                    }
                    {
                        tags ? tags.map((tag: any) => {
                            if (currentTag == tag.id) {
                                return <MenuItem type="active" onClick={() => { setCurrentTag(tag.id); setPageNb(null); setIsLoading(true) }} key={uuidv4()} title={tag.name} />
                            }
                            else {
                                return <MenuItem type="none" onClick={() => { setCurrentTag(tag.id); setPageNb(null); setIsLoading(true) }} key={uuidv4()} title={tag.name} />
                            }
                        })
                            :
                            <></>

                    }
                </Row>
                <Row width="100%" flexWrap="wrap" justifyContent="space-between" >
                    {
                        news && !isLoading
                            ?
                            display().map((article: any, index: number) => {
                                return article
                            })
                            :
                            displayLoading()
                    }
                </Row>
                <Column flex="1" width="100%" justifyContent="flex-end">
                <Row flex='1' justifyContent="center" p="1rem">

                    {pageCount && pageNb !== null && !isLoading ?
                        <Pagination count={pageCount} page={pageNb} onChange={(event, value) => { setPageNb(value); setIsLoading(true); window.scrollTo(0, 0) }} />
                        :
                        <></>


                    }
                </Row>
            </Column>

               
            </Column>
        </Row >

    )
})



export default Infos