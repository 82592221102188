import * as React from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Text } from ".";

type Props = {
    children: React.ReactNode;
    to: string;
}

const TitleCardLink = React.memo<Props>(({ children, to, ...props }) => {
    return (
        <Text as="h3" mt="10px">
            <StyledLink to={to} {...props}>
                    {children}
            </StyledLink>
        </Text>
    );
});

const StyledLink = styled(Link)(
    {
        fontSize: "36px !important",
        color: "#171D45",
        textDecoration: 'none',
        fontWeight: 'bold',
        "&:hover": {
            color: "#616164"
        }
    }
)

export default TitleCardLink;