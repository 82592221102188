import styled, { createGlobalStyle, keyframes } from "styled-components";
import ThemeProvider from "./ThemeProvider";
import { variant } from "styled-system";
import Button from "./Button";
import { View, Column, Row, Container, Text } from "./Common";
import Loader from "./Loader";
import Icon from "./Icon";
import theme from "./theme";
export {
  ThemeProvider,
  theme,
  styled,
  Button,
  Column,
  Container,
  Loader,
  Row,
  Text,
  Icon,
  View,
  createGlobalStyle,
  keyframes,
  variant,
};

export default styled;
