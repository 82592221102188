import React, { useState } from "react"
import { Row, Column, Text, View } from "../ui"
import Tubalise from "./images/TUBALISE.png"
import FacebookIcon from "./images/FacebookIcon.png"
import InstagramIcon from "./images/InstagramIcon.png"
import TwitterIcon from "./images/TwitterIcon.png"
import SpotifyIcon from "./images/SpotifyIcon.png"
import YoutubeIcon from "./images/YoutubeIcon.png"
import FacebookIconBlack from "./images/FacebookIconBlack.png"
import InstagramIconBlack from "./images/InstagramIconBlack.png"
import TwitterIconBlack from "./images/TwitterIconBlack.png"
import SpotifyIconBlack from "./images/SpotifyIconBlack.png"
import YoutubeIconBlack from "./images/YoutubeIconBlack.png"





const SocialsFooter = () => {

    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }


    return (

        <Column>
            <Row backgroundColor="secondary">
                <View
                    as="img"
                    flex="1"
                    src={Tubalise}
                    width="100%"
                    marginBottom={"-1px"}
                >


                </View>
            </Row>
            <Row backgroundColor="primary">
                <Row width="40%" m="auto" p="3rem">
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 1 ? <View as="a" m="auto" href="https://www.facebook.com/lechanchanlechantier" target="_blank"><View
                            as="img"
                            src={FacebookIconBlack}
                            height="20px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(1)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.facebook.com/lechanchanlechantier" target="_blank">
                                <View
                                    as="img"
                                    src={FacebookIcon}
                                    height="20px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(1)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 2 ? <View as='a' m="auto" href="https://www.instagram.com/lechantier.radio/" target="_blank"><View
                            as="img"
                            src={InstagramIconBlack}
                            height="20px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(2)}
                            onMouseLeave={() => mouseLeave()}
                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.instagram.com/lechantier.radio/" target="_blank">
                                <View
                                    as="img"
                                    src={InstagramIcon}
                                    height="20px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(2)}
                                    onMouseLeave={() => mouseLeave()}
                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 3 ? <View as='a' m="auto" href="https://twitter.com/le_chantier" target="_blank"><View
                            as="img"
                            src={TwitterIconBlack}
                            height="20px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(3)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View> </View> :
                            <View as='a' m="auto" href="https://twitter.com/le_chantier" target="_blank">
                                <View
                                    as="img"
                                    src={TwitterIcon}
                                    height="20px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(3)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>}
                    </Column>
                    <Column flex="1" p="1rem">
                        {hovered && hoveredId === 4 ? <View as='a' m="auto" href="https://open.spotify.com/user/knxt3628l2t3m24usem3fil1l?si=E_R9F5yuRBeNqd9WIMTJjw" target="_blank"><View
                            as="img"
                            src={SpotifyIconBlack}
                            height="20px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(4)}
                            onMouseLeave={() => mouseLeave()}

                        >
                        </View> </View> :
                            <View as='a' m="auto" href="https://open.spotify.com/user/knxt3628l2t3m24usem3fil1l?si=E_R9F5yuRBeNqd9WIMTJjw" target="_blank">
                                <View
                                    as="img"
                                    src={SpotifyIcon}
                                    height="20px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(4)}
                                    onMouseLeave={() => mouseLeave()}

                                >
                                </View></View>
                        }
                    </Column>
                    <Column flex="1" p="1rem">

                        {hovered && hoveredId === 5 ? <View as='a' m="auto" href="https://www.youtube.com/channel/UCnH5Q4WhXKkMpz7EZNDnXEA" target="_blank"><View
                            as="img"
                            src={YoutubeIconBlack}
                            height="20px"
                            m="auto"
                            onMouseEnter={() => mouseEnter(5)}
                            onMouseLeave={() => mouseLeave()}
                            
                        >
                        </View></View> :
                            <View as='a' m="auto" href="https://www.youtube.com/channel/UCnH5Q4WhXKkMpz7EZNDnXEA" target="_blank">
                                <View
                                    as="img"
                                    src={YoutubeIcon}
                                    height="20px"
                                    m="auto"
                                    onMouseEnter={() => mouseEnter(5)}
                                    onMouseLeave={() => mouseLeave()}
                                    
                                >
                                </View></View>}

                    </Column>
                </Row>
            </Row>
        </Column >
    )




}









export default SocialsFooter