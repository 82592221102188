import React, { useRef, useCallback, useState, useEffect, FunctionComponent, memo } from "react";
import { View, Row, Column, Text } from "../ui";
import useApp from "../providers/AppProvider";
import moment, { Moment } from "moment";
import Playbtn from "./images/PLAY.png"
import Pausebtn from "./images/PAUSE.png"
import Playbtnhover from "./images/PLAYHOVER.png"
import Pausebtnhover from "./images/PAUSEHOVER.png"
import VolumeIcon from "./images/VOLUME.png"
import MuteIcon from "./images/MUTE.png"
import VolumeIconHover from "./images/VOLUMEHOVER.png"
import MuteIconHover from "./images/MUTEHOVER.png"
import RedDot from "./images/RedDot.png"
import { Slider, makeStyles, createMuiTheme, withStyles } from "@material-ui/core";
import { ThemeProvider } from "styled-components";
import WebRadio from "../scenes/WebRadio";
import Spinner from "react-spinner-material";



type props = {
  isMobile?: string
}

const PrettoSlider = withStyles({
  root: {
    color: '#ffffff',
    height: 6,
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -7,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#FFFFFF"
  },
  rail: {
    height: 6,
    borderRadius: 4,
    backgroundColor: "#272728",
    opacity: 1

  },
})(Slider);



interface State {
  current: number,
  duration: number,
  currentTarget: EventTarget,
  event: React.FormEvent<HTMLInputElement>

}

const theme = createMuiTheme({
  overrides: {
    MuiSlider: {
      track: {
        backgroundColor: "white"
      },
      thumbColorPrimary: {
        backgroundColor: "#ffffff"
      }
    }
  }
});





const Player = memo<props>(({ isMobile }) => {
  const { PlayerLink, setPlayerLink } = useApp();
  const { setPlayerArtist, setPlayerImage, setPlayerTitle, playerWebRadio, setPlayerWebRadio, PlayerArtist, PlayerImage, PlayerTitle } = useApp();
  const playerRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(800);
  const [current, setCurrent] = useState(0);
  const [volume, setVolume] = useState(0);
  const { setExample, example } = useApp();
  const [hovered, setHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(Number);
  const [liveDisplay, setLiveDisplay] = useState(false);
  const [intervalId, setIntervalId] = useState<number>();
  const [link, setLink] = useState<string>(PlayerLink);
  const [isWebRadio, setIsWebRadio] = useState(false);
  const [autoplayNb, setAutoplayNb] = useState(0);
  const [isLive, setIsLive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const mouseEnter = (id: number) => {
    setHovered(true);
    setHoveredId(id)

  }

  const mouseLeave = () => {
    setHovered(false);
    setHoveredId(0)
  }



  useEffect(() => {
    if (intervalId) {
      for (let i = 0; i < intervalId; i++) {
        clearInterval(i);
      }
    }
  }, [intervalId])


  const TimeFormat = (time: number) => {

    // Hours, minutes and seconds
    let hrs = ~~(time / 3600);
    let mins = ~~((time % 3600) / 60);
    let secs = ~~time % 60;
    let ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  useEffect(() => {
    setIsLoading(true);
  }, [])


  useEffect(() => { }, [])
  console.log('LINKKKKKKK', link)
  const play = useCallback(() => {

    if (!playerRef.current) {
      return;
    }
    if (!isPlaying) {
      
      intervalId ? clearInterval(intervalId) : console.log("🚧")
      playerRef.current?.play();
      setIsPlaying(true);
      if (navigator) {
        if ('mediaSession' in navigator) {
          if (navigator.mediaSession) {
            navigator.mediaSession.metadata = new MediaMetadata({
              title: PlayerTitle,
              artist: PlayerArtist,
              album: "",
              artwork: [
                { src: PlayerImage, sizes: '96x96', type: 'image/png' },

              ]
            });

          }
        }
      }
      intervalId ? clearInterval(intervalId) : console.log("")
      setIntervalId(setInterval(() => {
        setCurrent((Number)(playerRef.current?.currentTime))
        setLiveDisplay(!liveDisplay)
        // console.log("DURATION :" + playerRef.current?.duration)
        if (playerRef.current?.duration === Infinity || link.includes("radioking")) {

          switch (link) {

            //nouveautés du moment
            case "https://www.radioking.com/play/lechantier-lesnouveautesdumoment/373765":

              setIsWebRadio(true);
              setPlayerWebRadio("Les Nouveautés du moment")
              fetch("https://api.radioking.io/widget/radio/lechantier-lesnouveautesdumoment/track/current")
                .then((response) => { return response.json() })
                .then((meta) => {


                  setPlayerTitle(meta.title);
                  setPlayerArtist(meta.artist);
                  // setPlayerImage("https://www.radioking.com/api/track/cover/5338e24c-f007-4b45-b1bc-4558b6df4b3f")
                  if (meta.cover !== null) {
                    setPlayerImage(meta.cover);
                  } else {
                    setPlayerImage("https://www.radioking.com/api/track/cover/5338e24c-f007-4b45-b1bc-4558b6df4b3f")
                  }
                }); break;
            //Nuit tu me fais peur
            case "https://www.radioking.com/play/lechantier-nuittumefaispeur/373756":
              setIsWebRadio(true)
              setPlayerWebRadio("Nuit tu me fais peur")
              fetch("https://api.radioking.io/widget/radio/lechantier-nuittumefaispeur/track/current")
                .then((response) => { return response.json() })
                .then((meta) => {


                  setPlayerTitle(meta.title);
                  setPlayerArtist(meta.artist);
                  //setPlayerImage("https://www.radioking.com/api/track/cover/4a15b00c-a769-4d11-9a74-1aaf0b1e9cd5")
                  if (meta.cover !== null) {
                    setPlayerImage(meta.cover);
                  } else {
                    setPlayerImage("https://www.radioking.com/api/track/cover/4a15b00c-a769-4d11-9a74-1aaf0b1e9cd5")
                  }
                }); break;
            //La Messe De Minuit
            case "https://www.radioking.com/play/lechantier-lamessedeminuit/373774":
              setIsWebRadio(true)
              setPlayerWebRadio("La Messe de Minuit")
              fetch("https://api.radioking.io/widget/radio/lechantier-lamessedeminuit/track/current")
                .then((response) => { return response.json() })
                .then((meta) => {


                  setPlayerTitle(meta.title);
                  setPlayerArtist(meta.artist);
                  //setPlayerImage("https://www.radioking.com/api/track/cover/4b907c5f-8d11-4618-801d-053ba94385f3")
                  if (meta.cover !== null) {
                    setPlayerImage(meta.cover);
                  } else {
                    setPlayerImage("https://www.radioking.com/api/track/cover/4b907c5f-8d11-4618-801d-053ba94385f3")
                  }
                }); break;
            default:
              //setIsLive(true)
              setIsWebRadio(false)
              setPlayerWebRadio('')
              fetch("https://api.radioking.io/widget/radio/lechantier-radio/track/current")
                .then((response) => { return response.json() })
                .then((meta) => {
                  setPlayerTitle(meta.title);
                  setPlayerArtist(meta.artist);
                  if (meta.cover !== null) {
                    setPlayerImage(meta.cover);
                  } else {
                    setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc")
                  }
                }); break;
          }
          if (navigator) {
            if ('mediaSession' in navigator) {
              if (navigator.mediaSession) {
                navigator.mediaSession.metadata = new MediaMetadata({
                  title: PlayerTitle,
                  artist: PlayerArtist,
                  album: "",
                  artwork: [
                    { src: PlayerImage, sizes: '96x96', type: 'image/png' },

                  ]
                });

              }
            }
          }

        } else {
          setIsWebRadio(true)
          setPlayerWebRadio('')
        }
      }, 1000))




    } else {
      if (link === "https://www.radioking.com/play/lechantier-radio/182095") {
        setIsLive(false)
      }
      clearInterval(intervalId);
      playerRef.current?.pause();
      //setExample([...example, `Played at ${moment().toISOString()}`]);
      setIsPlaying(false);

    }

  }, [isPlaying, link]);

  const handleChange = (event: React.ChangeEvent<{}>, value: number | number[]) => {

    if (!Array.isArray(value)) {
      setCurrent(value)
      if (playerRef.current !== null) {
        if (current !== Infinity) {
          playerRef.current.currentTime = value;
        }
      }

    }


  }




  const mute = () => {
    let volumeUpdate;
    if (volume === 1) {
      volumeUpdate = 0

    }
    else {
      volumeUpdate = 1
    }
    setVolume(volumeUpdate)
    if (playerRef.current !== null) {
      playerRef.current.volume = volume

    }
  }

  useEffect(() => {
    // console.log('isLive: ' + isLive)
    if (autoplayNb >= 1) {
      playerRef.current?.addEventListener("canplay", () => {
        clearInterval(intervalId)
        play();
      })
    }
    setAutoplayNb(autoplayNb + 1)
    

  }, [link, isLive])


  useEffect(() => {
    // console.log("isWebRadio: " + isWebRadio)
  }, [isWebRadio])


  useEffect(() => {
    isLoading ? console.log("OK") : setIsLoading(true)
    const clear = () => {
      intervalId ? clearInterval(intervalId) : console.log("✔")
      play();
    }
    isPlaying ? play() : console.log(isPlaying);
    if (PlayerLink === "live") {
      if (intervalId) {
        for (let i = 0; i < intervalId + 1; i++) {
          clearInterval(i)
        }
      }
      setLink("https://www.radioking.com/play/lechantier-radio/182095")

    } else {
      setLink(PlayerLink)
    }
    playerRef.current?.load();
    playerRef.current?.addEventListener("canplay", () => {
      setDuration((Number)(playerRef.current?.duration))
      setCurrent((Number)(playerRef.current?.currentTime));
      setIsLoading(false);
      if (PlayerLink === "https://www.radioking.com/play/lechantier-radio/182095") {
        setIsLive(true);
        setIsWebRadio(false);
      }
      //isPlaying ? clear() : console.log("🚧");
      playerRef.current?.duration === Infinity ? console.log("🚧") : setIsWebRadio(true)
      playerRef.current?.addEventListener("play", (val) => {
        setIsPlaying(true);
      });
      playerRef.current?.addEventListener("pause", (val) => {
        setIsPlaying(false);
      });
    })



  }, [PlayerLink]);

  useEffect(() => {
    console.log('playerWebRadio', '-' + playerWebRadio + '-')
  }, [playerWebRadio])


  const Pause = <View cursor="pointer"
    onClick={() => {
      if (autoplayNb === 2) {
        setPlayerLink("live")
        setAutoplayNb(autoplayNb + 1)
      } else { play() }
    }}
    onMouseEnter={() => mouseEnter(1)}
    onMouseLeave={() => mouseLeave()}
    as="img"
    width="50"
    height="50"
    src={Pausebtn} />
  const Play = <View cursor="pointer"
    onClick={() => {
      if (autoplayNb === 2) {
        setPlayerLink("live")
        setAutoplayNb(autoplayNb + 1)
      } else { play() }
    }}
    onMouseEnter={() => mouseEnter(1)}
    onMouseLeave={() => mouseLeave()}
    as="img"
    width="50"
    height="50"
    src={Playbtn} />
  const PlayHover = <View cursor="pointer"
  onClick={() =>{ 
    if(autoplayNb === 2) {
      setPlayerLink("live")
      setAutoplayNb(autoplayNb + 1)
    } else { play() }
  }}
    onMouseEnter={() => mouseEnter(1)}
    onMouseLeave={() => mouseLeave()}
    as="img"
    width="50"
    height="50"
    src={Playbtnhover} />
  const PauseHover = <View cursor="pointer"
  onClick={() =>{ 
    if(autoplayNb === 2) {
      setPlayerLink("live")
      setAutoplayNb(autoplayNb + 1)
    } else { play() }
  }}
    onMouseEnter={() => mouseEnter(1)}
    onMouseLeave={() => mouseLeave()}
    as="img"
    width="50"
    height="50"
    src={Pausebtnhover}
  />
  const VolumeDisplay = () => {
    if (hovered && (hoveredId === 3)) {
      return (
        <Row width="30px" justifyContent="flex-start">
          <View
            //p="1rem"
            alignItems="center"
            as="img"
            src={VolumeIconHover}
            //width="100%"
            height="auto"
            maxHeight="18px"
            onMouseEnter={() => { setHovered(true); setHoveredId(3) }}
            onMouseLeave={() => { setHovered(false); setHoveredId(0) }}
            onClick={() => { mute() }}
            cursor="pointer"
          ></View>
        </Row>
      )
    } else {
      return (
        <Row width="30px" justifyContent="flex-start">
          <View
            //p="1rem"
            alignItems="center"
            as="img"
            src={VolumeIcon}
            //width="100%"
            height="auto"
            maxHeight="18px"
            onMouseEnter={() => { setHovered(true); setHoveredId(3) }}
            onMouseLeave={() => { setHovered(false); setHoveredId(0) }}
            onClick={() => { mute() }}
            cursor="pointer"
          ></View>
        </Row>
      )

    }
  }

  const MuteDisplay = () => {
    if (hovered && (hoveredId === 2)) {
      return (
        <Row width="30px" justifyContent="flex-start">
          <View
            //p="1rem"
            alignItems="center"
            as="img"
            src={MuteIconHover}
            //width="100%"
            height="auto"
            maxHeight="18px"
            onMouseEnter={() => { setHovered(true); setHoveredId(2) }}
            onMouseLeave={() => { setHovered(false); setHoveredId(0) }}
            onClick={() => { mute() }}
            cursor="pointer"
          ></View>
        </Row>
      )
    } else {
      return (
        <Row width="30px" justifyContent="flex-start">
          <View
            // p="1rem"
            alignItems="center"
            as="img"
            src={MuteIcon}
            //width="100%"
            height="auto"
            maxHeight="18px"
            onMouseEnter={() => { setHovered(true); setHoveredId(2) }}
            onMouseLeave={() => { setHovered(false); setHoveredId(0) }}
            onClick={() => { mute() }}
            cursor="pointer"
          ></View>
        </Row>
      )
    }
  }




  return (
    <Row
      bg={"primary"}
      color="white"
      //boxShadow="1px 1px 3px grey"
      alignItems="center"
      height="88px"
      flex="1"
      pl=".3rem"
    >

      {isPlaying && hovered ? PauseHover : !isPlaying && hovered ? PlayHover : isPlaying && !hovered ? Pause : Play}


      <View
        ml="1rem"
        as="img"
        src={PlayerImage}
        height="88px"
        width="88px"
      />

      {
        isLoading 
        ?
        <Column p="1rem"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Column> 
        : 
        isWebRadio && (isMobile !== "yes") 
        ?
        <Column p="1rem">
          <Text fontWeight="bold" style={{ textTransform: "uppercase" }}>{PlayerArtist}</Text>
          <Text color="secondary">{PlayerTitle}</Text>
          <Row flex="1">
            <Row style={{ borderRightColor: 'white', borderRightWidth: '1px', borderRightStyle: 'solid', paddingRight: '6px', marginRight: '6px' }}> 
              <Text color="white" style={{ color: "white", fontWeight: "bold", textTransform: 'uppercase', fontFamily: 'Barlow Condensed' }}>{playerWebRadio ? playerWebRadio : 'REPLAY'}</Text>
            </Row>
            <Text cursor="pointer" style={{ color: "black", fontWeight: "bold", textTransform: 'uppercase', fontFamily: 'Barlow Condensed' }} onClick={() => {
              setPlayerArtist("Le Chantier");
              setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc");
              setPlayerTitle("Le Chantier");
              setPlayerLink("https://www.radioking.com/play/lechantier-radio/182095")

            }}>Revenir sur Le Chantier</Text>
          </Row>
        </Column>
        :
        !isLive && (isMobile !== "yes") && (autoplayNb > 1) ?
          <Column ml="1em">
            <Text fontWeight="bold" style={{ textTransform: "uppercase" }}>{PlayerArtist}</Text>
            <Text color="secondary">{PlayerTitle}</Text>
            <Row flex="1">
              <Text cursor="pointer" style={{ color: "black", fontWeight: "bold", textTransform: 'uppercase' }} onClick={() => {
                setPlayerArtist("Le Chantier");
                setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc");
                setPlayerTitle("Le Chantier");
                setPlayerLink("live")
                setTimeout(() => {
                  setPlayerLink('https://www.radioking.com/play/lechantier-radio/182095')

                }, 10);
              }}>Revenir au direct</Text>

            </Row>
          </Column>
          :
          isWebRadio && (isMobile === "yes") ?
            <Column p="1rem">
              {/* <Row flex="1">
            <Text pr='.3rem' color="white">{ playerWebRadio}</Text>
              <Text cursor="pointer" style={{ color: "black", fontWeight: "bold" }} onClick={() => {
                setPlayerArtist("Le Chantier");
                setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc");
                setPlayerTitle("Le Chantier");
                setPlayerLink("https://www.radioking.com/play/lechantier-radio/182095")
                
              }}>Revenir sur Le Chantier</Text>
            </Row> */}
              <Text fontWeight="bold" style={{ textTransform: "uppercase", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{PlayerArtist.length > 6 ? PlayerArtist.substr(0, 7) + "..." : PlayerArtist}</Text>
              <Text color="secondary" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{PlayerTitle.length > 6 ? PlayerTitle.substr(0, 7) + "..." : PlayerTitle}</Text>
            </Column>

            :

            !isLive && (isMobile === "yes") && autoplayNb > 1 ?
              <Column p="1rem">
                {/* <Row flex="1">
              
              <Text cursor="pointer" style={{ color: "black", fontWeight: "bold" }} onClick={() => {
                setPlayerArtist("Le Chantier");
                setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc");
                setPlayerTitle("Le Chantier");
                setPlayerLink("live")
                setTimeout(() => {
                  setPlayerLink('https://www.radioking.com/play/lechantier-radio/182095')
                  
                }, 10);
              }}>Revenir sur le direct</Text>
              
            </Row> */}
                <Text fontWeight="bold" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", textTransform: 'uppercase' }}>{PlayerArtist.length > 6 ? PlayerArtist.substr(0, 7) + "..." : PlayerArtist}</Text>
                <Text color="secondary" style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{PlayerTitle.length > 6 ? PlayerTitle.substr(0, 7) + "..." : PlayerTitle}</Text>
              </Column>

              :

              <Column p="1rem" width="20%">
                <Text fontWeight="bold" style={{ textTransform: "uppercase" }}>{PlayerArtist}</Text>
                <Text color="secondary" >{PlayerTitle}</Text>
              </Column>

      }


      {isLoading ? <Row flex="1" alignContent="center"></Row> : duration !== Infinity ? <Column flex="1" pl="1rem"><ThemeProvider theme={theme}> <PrettoSlider value={current} max={duration} min={0} onChange={(e, value: number | number[]) => { handleChange(e, value) }} /> </ThemeProvider></Column> :
        <Row flex="1" alignContent="center">


        </Row>}

      {/*<ProgressBar total={10} current={9} onMute={() => play()} />*/}
      <audio
        src={link}
        ref={playerRef}
        title={PlayerTitle}
      >
        {/*https://listen.radioking.com/radio/141961/stream/182089 */}

      </audio>
      <Column>
        {isLoading ? <Row flex="1" alignContent="center"></Row> : !link.includes('radioking') ?
          <Text p="1rem">{TimeFormat(current)}</Text> :
          <></>
        }
      </Column>
      <Column>
        {volume === 1 ? MuteDisplay() : VolumeDisplay()}
      </Column>
      <Column>

      </Column>

    </Row>
  );
});

export default Player;
