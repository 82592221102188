import React, { useState, useEffect, memo } from "react"
import { Row, Text, Column, View } from "../ui"
import MenuItem from "./MenuItem";
import { type } from "os";

type props = {
    nbSaisons : number 
}


const PodcastMenu = memo<props>(({nbSaisons}) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(0);
    const [active, setActive] = useState(true);
    const [activeId, setActiveId] = useState(1);

  
    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    const handleClick = (id: number) => {
        setActive(true);
        setActiveId(id)
    }


    return (

        <Row height="60px">
           
            <Column onMouseEnter={() => mouseEnter(1)} cursor="pointer" onClick={() => handleClick(1)} onMouseLeave={() => mouseLeave()}>
                {hovered === true && hoveredId === 1 || active === true && activeId === 1 ?
                    <MenuItem onClick={() => {}} title="Toutes les saisons" type="active" /> :
                    <MenuItem onClick={() => {}} title="Toutes les saisons" type="none" />

                }
            </Column>
            {/* <Column onMouseEnter={() => mouseEnter(2)} cursor="pointer" onClick={() => handleClick(2)} onMouseLeave={() => mouseLeave()}>
                {hovered === true && hoveredId === 2 || active === true && activeId === 2 ?
                    <MenuItem title="Saison 1" type="active" /> :
                    <MenuItem title="Saison 1" type="none" />

                }
            </Column>
            <Column onMouseEnter={() => mouseEnter(3)} cursor="pointer" onClick={() => handleClick(3)} onMouseLeave={() => mouseLeave()}>
                {hovered === true && hoveredId === 3 || active === true && activeId === 3 ?
                    <MenuItem title="Saison 2" type="active" /> :
                    <MenuItem title="Saison 2" type="none" />

                }
            </Column>
            <Column onMouseEnter={() => mouseEnter(4)} cursor="pointer" onClick={() => handleClick(4)} onMouseLeave={() => mouseLeave()}>
                {hovered === true && hoveredId === 4 || active === true && activeId === 4 ?
                    <MenuItem title="Saison 3" type="active" /> :
                    <MenuItem title="Saison 3" type="none" />

                }
            </Column>
            <Column onMouseEnter={() => mouseEnter(5)} cursor="pointer" onClick={() => handleClick(5)} onMouseLeave={() => mouseLeave()}>
                {hovered === true && hoveredId === 5 || active === true && activeId === 5 ?
                    <MenuItem title="Saison 4" type="active" /> :
                    <MenuItem title="Saison 4" type="none" />

                }
            </Column> */}



        </Row>
    )




})
export default PodcastMenu