import React, { useEffect, useState, FormEvent } from "react" 
import useApp from "../providers/AppProvider"
import { Row ,Text, Column, View, Button } from "../ui";
import moment, { Moment }  from "moment";
import { DateTimePicker, DatePickerView } from '@material-ui/pickers'
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import GreenHr from "../components/GreenHr"
import VioletLinkTag from "../ui/VioletLinkTag";
// import Button from "../ui/VioletLink";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const materialTheme = createMuiTheme({
   
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#5DCD69",
        },
        
      },
      MuiPickerDTHeader : {
          header : {
          backgroundColor : "#5DCD69"
          }

      },
    
     
      MuiPickerDTTabs :{
          tabs : {
          backgroundColor : "#5DCD69"
          },
      }
      ,
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: "#5DCD69",
          // color: "white",
        },
      },
      MuiPickersClock : {
          pin : {
              backgroundColor : "#6C00FF"
          }
      },
      MuiPickersClockPointer : {
          pointer : {
              backgroundColor : "#6C00FF"
          }
      },

      MuiPickersClockNumber : {
          clockNumberSelected : {
              backgroundColor : "#6C00FF"
              
          },
          thumb : {
              backgroundColor : "#6C00FF" ,
              color : "#6C00FF" ,
              primaryColor : "#6C00FF",
              secondaryColor : "6C00FF"
          },
          noPoint : {
              backgroundColor : "#6C00FF"
          },

      },
      
      MuiPickersDay: {
        day: {
          color: "black",
        },
        daySelected: {
          backgroundColor: "#6C00FF",
        },
        dayDisabled: {
          color: "#050505",
        },
        current: {
          color: "#5DCD69",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#5DCD69",
        },
      },
      MuiTabs : {
          indicator : {
            backgroundColor : "#6C00FF" ,
            borderColor : "#6C00FF"
          },
      },
      
    },
  });

const TitreDiffuse = () => {
    const {lastSongs , setLastSongs} = useApp();
    const [date , setDate] = useState(moment());

  useEffect(() => {
    
    getTitresDiffuses();
  
  } , [date])

  // setInterval(() => {
  //   setDate(moment())
  // } , 15000)

  const getTitresDiffuses = () => {
    fetch("https://api.radioking.io/widget/radio/lechantier-radio/track/ckoi?limit=16&date=" + date.toISOString())
    .then((response) => {return response.json()})
    .then((data) => {setLastSongs(data);})
  }

  const handleChange = (d : MaterialUiPickersDate) => { 
    setDate(moment(d));
  }

  const displayArray = () => {
      let feed : any = [];
      lastSongs.map((song : any ) => {
        if(song.cover_url === null){
            song.cover_url = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc"
        }
        feed.push(<Column p="1rem" width="200px"> 
        <View as="div" width="200px" height="200px" style={{backgroundImage : `url(${song.cover_url})` , backgroundSize :"cover" , position : "relative"}} >
          <View width="200px" height="200px" style={{backgroundColor : "rgba(0,0,0,0)"}}>
          </View> </View> 
          <Text fontWeight="bold" color="black"  p=".3rem" >{moment(song.started_at).format("LT")}</Text>
             <Text style={{}} fontWeight="bold" color="black" pl=".3rem" >{song.artist }<br /></Text>
             <Text color="black" p=".3rem">{song.title}</Text>
        </Column>)

      })
      return feed.slice(0, feed.length >= 12 ? 12 : feed.length >= 9 ? 9 : feed.length >= 6 ? 6 : 3);
     
  }
  
  
    return (
        <Column width={"100%"}>
          <Column width="100%" >
            <Row width="100%">
                <Text as='h1' m="1.5rem">TITRES DIFFUSÉS</Text>
            </Row>
            <Row flex="1">
                <GreenHr />
            </Row>
          </Column>
          <Row>
            <Text as="h3">Sélectionnez la date et l'heure pour retrouver votre titre</Text>
          </Row>
          <Row justifyContent="center" style={{ padding: '20px' }}>
            <ThemeProvider theme={materialTheme}>
              <DateTimePicker cancelLabel="Fermer" clearLabel="Effacer" ampm={false} format="DD/MM/YYYY HH:mm" value={date} onChange={(date : MaterialUiPickersDate) => handleChange(date)} />
            </ThemeProvider>
            <VioletLinkTag handleClick={() => setDate(moment())}>Actualiser</VioletLinkTag>
          </Row>
          <Column>
            <Row flexWrap="wrap"  flexBasis="80%"  justifyContent="start">
              {
                lastSongs.length >= 1 
                ?
                displayArray()
                : 
                <></>
              }
            </Row >
          </Column>
        </Column>
    )
}

export default TitreDiffuse