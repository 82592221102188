import styled from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  system,
  textStyle,
  TextStyleProps,
  typography,
  TypographyProps,
} from "styled-system";

export type StyledSystemProps = SpaceProps &
  ColorProps &
  FlexboxProps &
  TypographyProps &
  LayoutProps &
  GridProps &
  BackgroundProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  TextStyleProps & {
    transform?: string;
    cursor?: string;
    ref?: any;
    variant?: string;
    transition?: string;
  };

const customProps = system({
  transform: true,
  outline: true,
  cursor: true,
});

const commonCompose = compose(
  space,
  color,
  typography,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
  shadow,
  customProps,
  textStyle
);

export const View = styled("div")<StyledSystemProps>(commonCompose);

export const Container = styled(View)({
  display: "flex",
});

export const Column = styled(View)({
  display: "flex",
  flexDirection: "column",
});

export const Row = styled(View)({
  display: "flex",
  flexDirection: "row",
});

export const Text = styled("span")<StyledSystemProps>(commonCompose);
Text.defaultProps = {
  display: "block",
};
