import React, { memo } from "react";
import { Column, Row } from "../ui";
import Player from "./Player"
import Navbar from "./Navbar"
import Footer from "./Footer"
import WidgetTitres from "./WidgetTitres";
import SideBar from "./SideBar";



type Props = {
  children: React.ReactNode;
};

const Layout = memo<Props>(({ children }) => {

  return (
    <Column width="100%">
      <Navbar />
      <Row flex="1" justifyContent="center">
        <Row width="100%" justifyContent="center" style={{ maxWidth: "1100px" }}>
          <Row width="100%" justifyContent="space-between" >
            <Column width="100%" >
              <Row  width="90%">
                {children}
              </Row>
            </Column>
            <Column width="300px"><SideBar /></Column>
          </Row>
        </Row>
      </Row>

      <Footer />
    </Column>
  );
});

export default Layout;
