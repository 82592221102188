import React, { memo, useState } from "react"
import { Link } from "react-router-dom";
import { Column, View, Text, Row } from "../ui"
import { v4 as uuidv4 } from "uuid"
import VioletLink from "../ui/VioletLink";
import TitleCardLink from "../ui/TitleCardLink";
import VioletLinkTag from "../ui/VioletLinkTag";


const decodeHtml = (html : string) =>  {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

type props = {
    src: string,
    title: string,
    podcastTitle: string,
    description: string,
    date?: string,
    slug?: string,
    tag?: number
}

const NewsCardRow = memo<props>(({ src, title, date, podcastTitle, description, slug, tag }) => {
    const [hovered , setHovered ] = useState(false)
    const [hoverId , setHoverId] = useState(0);

    return (
        <Row flex="1" justifyContent="space-between">
            <Column p="0.5rem" pr="2.1rem" width="100%" >
                <Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={"/infos/" + slug}>
                    <View
                        ml="auto"
                        mr="auto"
                        height="194px"
                        width="100%"
                        style={{ backgroundImage: `url(${src})`, backgroundSize: "cover" , backgroundPosition : "center" }} >
                    </View>
                </Link>

            </Column >

            <Column p=".5rem" pb="1rem" pt="0rem" width="100%">
                <VioletLinkTag to={`/infos/?tag=${tag}`}>
                    <Text m=".5px" as="p" fontWeight="bold">{decodeHtml(podcastTitle)}</Text>
                </VioletLinkTag>
                <TitleCardLink to={"/infos/" + slug}>
                    {decodeHtml(title)}
                </TitleCardLink>
                <Text as="p" m=".5px" mt=".5rem" mb=".5rem" color="black" fontWeight="200">{date}</Text>
                <Text>{decodeHtml(description)}</Text>
                <VioletLink to={"/infos/" + slug}>
                    Lire la suite
                </VioletLink>
            </Column>

        </Row >
    )

})



export default NewsCardRow