import React from "react"
import { Column, View } from "../ui"

const LoadingInfos = () => {

    return (
        <Column mt="30px" width="100%">
            <View width="100px" mb="10px" height="15px" style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="400px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            <View width="100%" mb="10px" height="15px"  style={{backgroundColor : "whitesmoke"}}></View>
            
        </Column>
    )

}

export default LoadingInfos