import * as React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { View, Row, StyledSystemProps } from "./Common";
import Loader from "./Loader";

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  onClick: () => void;
} & StyledSystemProps;

const Button = React.memo<Props>(({ children, isLoading, ...props }) => {
  return (
    <ButtonWrapper
      as="button"
      height="2rem"
      border="0"
      color="primary"
      fontSize="1rem"
      borderRadius="3px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      px="0.5rem"
      fontWeight="600"
      flexShrink={0}
      {...(props as any)}
    >
      <Row justifyContent="center" alignItems="center">
        <View ml={isLoading ? "calc(15px + 0.5rem)" : undefined}>
          {children}
        </View>
        {isLoading && (
          <View ml="0.5rem">
            <Loader size={15} color="grey" />
          </View>
        )}
      </Row>
    </ButtonWrapper>
  );
});

const ButtonWrapper = styled(View)(
  {
    appearance: "none",
    outline: "none",
    "background-color": "#eff3f6",
    "background-image": "linear-gradient(-180deg,#fafbfc,#eff3f6 90%)",
    "background-repeat": "repeat-x",
    "background-position": "-1px -1px",
    "background-size": "110% 110%",
    border: `1px solid rgba(27,31,35,.2)`,
    cursor: "pointer",
    "white-space": "nowrap",
    "&:hover": {
      "background-color": " #e6ebf1",
      "background-image": " linear-gradient(-180deg,#f0f3f6,#e6ebf1 90%)",
      "background-position": " -.5em",
      "border-color": " rgba(27,31,35,.35)",
    },
    "&:active": {
      "background-color": " #e9ecef",
      "background-image": " none",
      "border-color": " rgba(27,31,35,.35)",
      "box-shadow": " inset 0 0.15em 0.3em rgba(27,31,35,.15)",
    },
    "&:disabled": {
      "background-color": "grey",
      color: "#999",
      "pointer-events": "none",
    },
  },
  variant({
    variants: {
      secondary: {
        color: "#24292e",
        bg: "transparent",
      },
      modalCTA: {
        height: "auto",
        p: "1rem",
        fontSize: "1rem",
        borderRadius: "0.75rem",
        mt: "0.5rem",
      },
    },
  })
);

export default Button;
