import React, { memo, useState, useEffect } from "react"
import { Column, View, Text } from "../ui"
import Playbtn from "./images/PLAY.png"
import useApp from "../providers/AppProvider"
import { Redirect, Link } from "react-router-dom"
import VioletLink from "../ui/VioletLink"





type props = {
    image: string,
    episodeTitle: string,
    description: string,
    episodeLink: string,
    podcastName : string,
    episode? : string,
    duration : number,
    redirect? : string

}

const PodcastCard = memo<props>(({ image, episodeTitle,podcastName, episode,duration , episodeLink , description , redirect }) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);
    const {setPlayerLink ,setPlayerArtist , setPlayerImage , setPlayerTitle, setIsPodcast } = useApp();

    const trimString = (text : string) => {
        let sentences = text.split("[.!?\\-]")
        return sentences[0] + sentences[1]
    }

    const TimeFormat = (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    useEffect(() => {
       
    },[])
    return (
    <Column width="344px" p="0.5rem">
            <Column
                ml="auto"
                mr="auto"
                height="344px"
                width="100%"
                style={{ backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: 'center' }}
                onMouseEnter={() => mouseEnter(1)}
                onMouseLeave={() => mouseLeave()}
                onClick={() => {setPlayerLink(episodeLink) ; setPlayerArtist(podcastName) ; setPlayerImage(image) ; setPlayerTitle(episodeTitle);}}
                cursor="pointer"
            >
                {hovered && hoveredId === 1 ? <View
                    style={{ backgroundColor: "rgba(0,0,0,.4)", transition: "ease-in .1s" }}
                    width="100%"
                    height="344px"
                    position="relative"
                >
                    <View
                        style={{ transition: "ease-in .1s" }}
                        as="div"
                        height="60px"
                        width="60px"
                        m="auto"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                    >
                        <View
                            as="img"
                            src={Playbtn}
                            height="60px"
                            width="60px"
                            m="auto"
                            
                        ></View>
                    </View>
                </View>
                    :
                    <View></View>}
            </Column>

            <Column p=".5rem" >
                <Text m=".5px" as="p" fontWeight="bold" style={{ color: "#6C00FF" }}>{podcastName.toUpperCase()}</Text>
                <Text m=".5px" as="h3" fontSize="2em" fontWeight="bold">{episodeTitle}</Text>
                {/* <Text as="p" m=".5px" mt=".5rem" mb=".5rem" color="black" fontWeight="200">EPISODE {episode} - {TimeFormat(duration)}</Text> */}
                <Text>{description}</Text>
                {/* {hovered  && hoveredId === 2?
                    <Link style={{color : "black" , textDecoration : "none"}} to={redirect? `/podcasts/${redirect}` : window.location.pathname}><Text cursor="pointer" onMouseEnter={() => {setHovered(true) ; setHoveredId(2)}} onMouseLeave={() => {setHovered(false) ; setHoveredId(0)}} m="0px" mt=".5rem" style={{ color: "#6C00FF" , fontWeight : "bold" }}>Lire la suite</Text></Link> :
                    <Text cursor="pointer" onMouseEnter={() => {setHovered(true) ; setHoveredId(2)}} onMouseLeave={() => {setHovered(false) ; setHoveredId(0)}} m="0px" mt=".5rem" style={{ color: "#6C00FF" }}>Lire la suite</Text>
                } */}
                {/* <VioletLink to={redirect? `/podcasts/${redirect}` : window.location.pathname}>
                    Lire la suite
                </VioletLink> */}
            </Column>

        </Column>
    ) 

})



export default PodcastCard