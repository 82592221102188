import React from "react"
import { View, Column } from "../ui"

const GreenHr = () => {
    return (
        
        <View as="div" width="100%" backgroundColor="primary" height="1px"></View>
       
    )
}



export default GreenHr