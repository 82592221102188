import React, { useState, useEffect, memo } from "react";
import { Column, Text, Row, Button, View } from "../ui";
import PodcastCard from "../components/PodcastCard";
import LastEvents from "../components/LastEvents";
import SideBar from "../components/SideBar";
import { Link, useLocation } from "react-router-dom";
import NewsCard from "../components/NewsCard";
import Spinner from "react-spinner-material";
import moment, { Moment } from "moment";

import Rubalise from "../components/images/rubalise.png"
import { v4 as uuidv4 } from "uuid"
import GreenHr from "../components/GreenHr";
import parse from "html-react-parser"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Podcast from "./Podcast";
import useApp from "../providers/AppProvider";
import NewsCardRow from "../components/NewsCardRow";
import LoadingCard from "../components/LoadingCard";
import LoadingCardHome from "../components/LoadingCardHome";
const stripHtml = (htmlString: string) => {
  return htmlString.replace(/<[^>]+>/g, '');
}


const displayNewsLoading = () => {
  return (
    <Column flex="1" p ="1rem">
    <Row>
    <LoadingCardHome />
    </Row>
    <Row justifyContent="space-around">
    <LoadingCard />
    <LoadingCard />
    </Row>
    </Column>
  )
}
const displayLoading = (count : number)=> {
  let feed : any = []
  for(let i = 0 ; i < 10 ; i++){
      feed.push(<LoadingCard />)
  }
  return feed.slice(0, count)

}


const Home = memo(() => {


  const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [hovered, setHovered] = useState(false);
  const [hoveredId, setHoveredId] = useState(0);
  const { setPage } = useApp();

  const mouseEnter = (id: number) => {
    setHovered(true);
    setHoveredId(id)

  }

  const mouseLeave = () => {
    setHovered(false);
    setHoveredId(0)
  }


  const [podcast, setPodcast] = useState<any>();
  const [news, setNews] = useState<any>();
  let feed: any = [];

  const display = () => {
    let feed: any = [];
    let ctt: string = ""
    news.map((article: any) => {
      let parsedHTML: any = parse(article.content.rendered);
      let srcUrl: string = "";
      for (let i = 0; i < parsedHTML.length - 1; i++) {
        if (parsedHTML[i].props) {
          if (parsedHTML[i].props.children.type === "img") {
            if(srcUrl === ""){
            srcUrl = parsedHTML[i].props.children.props.src;
            }


          } else {


          }
        }
      }
      if (srcUrl === "") {
        srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
      }

      feed.push(
        <NewsCard src={srcUrl}
          title={article.title.rendered}
          podcastTitle={stripHtml(parsedHTML[1].props.children).toUpperCase()}
          description={stripHtml(article.excerpt.rendered).replace('[&hellip;]', "...").replace(parsedHTML[1].props.children, "")}
          date={moment(article.date).format('ll')}
          slug={article.slug}
          tag={article.tags && article.tags.length > 0 ? article.tags[0] : null}
        />)
    });
    return feed.slice(0, 3)

  }

  const displayRandomPodcast = () => {
    let array: any = [];
    let display: any = [];
    console.log('display random', podcast)
    podcast.map((pod: any) => {
      array.push({id : pod.id ,  episode: pod.ep, podcast: pod.feed })
    })
    
    const sortedArray = array.sort((a: any, b: any) => { moment(a.episode.pubDate).diff(moment(b.episode.pubDate)) })
    display = sortedArray.map((ep: any) => {
      console.log('EP', ep);
      return (
        <PodcastCard
          episodeLink={ep.episode.enclosure.link}
          episodeTitle={ep.episode.title}
          image={ep.episode.thumbnail}
          description={stripHtml(ep.episode.description).substr(0, 200)}
          podcastName={ep.podcast.title}
          duration={ep.episode.enclosure.duration}
          redirect={ep.id}

        />
      )

    })
    // console.log(display.slice(0, 4))
    return display.slice(0, 4);
  }

  const displayRowCard = () => {
    let feed: any = [];
    news.map((article: any) => {
      let parsedHTML: any = parse(article.content.rendered);
      let srcUrl: string = "";
      for (let i = 0; i < parsedHTML.length - 1; i++) {
        if (parsedHTML[i].props) {
          if (parsedHTML[i].props.children.type === "img") {
            if(srcUrl === ""){
            srcUrl = parsedHTML[i].props.children.props.src;

            }


          }
        }
      }
      if (srcUrl === "") {
        srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
      }
      let content = "";
      if (stripHtml(article.excerpt.rendered).length > 228) {
        content = stripHtml(article.excerpt.rendered).substr(0, 229).replace('[&hellip;]', "...") + "..."
      }
      else {
        content = stripHtml(article.excerpt.rendered).replace('[&hellip;]', "...")
      }
      console.log('TAG', stripHtml(parsedHTML[1].props.children).toUpperCase())
      feed.push(
          <NewsCardRow src={srcUrl}
            title={article.title.rendered}
            podcastTitle={stripHtml(parsedHTML[1].props.children).toUpperCase()}
            description={content.replace(parsedHTML[1].props.children, "")}
            date={moment(article.date).format('ll')}
            slug={article.slug}
            tag={article.tags && article.tags.length > 0 ? article.tags[0] : null}
          />
      )

    });
    return feed.slice(0, 1)
  }


  useEffect(() => {
    const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
    fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=3")
      .then((response) => { return response.json() })
      .then((data) => { setNews(data) })
    fetch(("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=2"))
      .then((response) => { return response.json() })
      .then((data) => {
        let allPodcasts: any = [];
        data.map((pod: any) => {
          allPodcasts.push({ id: pod.id, link: stripHtml(pod.content.rendered) });
        })
        allPodcasts = allPodcasts.slice(0, 4);

        let podcastsToDisplay:Array<any> = [];

        allPodcasts.map((pod: any, index: number) => {
          fetch(ApiString + pod.link).then((response) => {
            return response.json() 
          })
          .then((data) => { 
              if (data.items && data.items.length > 0){
                podcastsToDisplay.push({ id: pod.id, ep: data.items[0], feed: data.feed });
              }
          })
          .then(() => {
            if (podcastsToDisplay.length === allPodcasts.length) {
              setPodcast(podcastsToDisplay)
            }
          })
        })


      })

  }, [])



  return (

    <Column width="100%">
      {/* <Navbar /> */}
      {/* <Row width="100%" justifyContent="center"> */}
        {/* <Column width="100%" maxWidth={"800px"} > */}
          {/* <Row width="90%" justifyContent="center" > */}
            {/* <Podcast /> */}
            {/* <Row flex="1" justifyContent="center"> */}
              {/* <Row width="100%" justifyContent="center" pb="2rem" maxWidth="1100px"> */}
                <Column width="100%">
                  <Text as="h1" m="1.5rem">INFOS</Text>
                  <GreenHr />
                  <Row width="100%" style={{ flexWrap: 'wrap' }}>
                    <Column width="100%">
                      {news ?
                        displayRowCard()
                        :
                        <></>
                      }
                      <GreenHr />
                      <Row flex="1" justifyContent="space-between">

                        {
                          news
                            ?
                            display().map((article: any, index: number) => {
                              if (index > 0) {
                                return article
                              }
                            })
                            :
                            displayNewsLoading()
                        }
                      </Row>
                    </Column>
                  </Row>
                  <Row justifyContent="center" mt="2rem">
                    <Link to="/infos" style={{ textDecoration: "none", color: "black" }}>
                      {
                        hovered && (hoveredId === 2) 
                        ?
                        <button
                          onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(2) }}
                          style={{ backgroundColor: "#4E547B", borderStyle: "none", borderRadius: 5, width: "auto", padding: "0.5rem", cursor: "pointer" }}>
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16  , fontWeight : "bold"}} color="white">Voir toutes les infos</Text>
                        </button>
                        :
                        <button
                          onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(2) }}
                          style={{ backgroundColor: "#5DCD69", borderStyle: "none", borderRadius: 5, width: "auto", padding: "0.5rem", cursor: "pointer" }}>
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16  , fontWeight : "bold"}} color="white">Voir toutes les infos</Text>
                        </button>}
                    </Link>


                  </Row>
                  <Column>
                    <Text as="h1" m="1.5rem">PODCASTS</Text>
                    <GreenHr />
                  </Column>
                  <Row pt="1rem" flexWrap="wrap" justifyContent="space-between" >

                    {podcast ? displayRandomPodcast() : displayLoading(4)}
                  </Row>
                  <Row justifyContent="center" mb="2rem" mt="2rem">
                    <Link to="/podcasts" style={{ textDecoration: "none", color: "black" }}>
                      {
                        hovered && (hoveredId === 3) 
                        ? 
                        <button 
                          onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(3) }} 
                          style={{ backgroundColor: "#4E547B", borderStyle: "none", borderRadius: 5, width: "auto", padding: "0.5rem", cursor: "pointer" }} >
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16 , fontWeight: "bold"}} color="white">Voir tous les podcasts</Text>
                        </button> 
                        :
                        <button
                          onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(3) }}
                          style={{ backgroundColor: "#5DCD69", borderStyle: "none", borderRadius: 5, width: "auto", padding: "0.5rem", cursor: "pointer" }}>
                          <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16 , fontWeight : "bold"}} color="white">Voir tous les podcasts</Text>
                        </button>}
                    </Link>


                  </Row>
                </Column>
              {/* </Row> */}
            {/* </Row> */}
          {/* </Row> */}
        {/* </Column> */}
      {/* </Row> */}


      {/* <Row flex="1" justifyContent="center">
        <Row width="100%" maxWidth={"1000px"} justifyContent="center">
          <Row flex="1" justifyContent="space-between">
            <Column width="100%" >
              <Row flex="1">
                <Row flex="1" >
                  <Column flex="1" >
                    <Row flex="1" >
                      <Column flex="1">
                        <Text as="h1" m="1.5rem">PODCASTS</Text>
                        <GreenHr />
                      </Column>
                    </Row>
                    <Row flex="1" justifyContent="center" >
                      <Column>
                        {podcast ? displayRandomPodcast().map((pod: any, index: number) => {
                          if (index % 2 === 0) {
                            return pod
                          } else {
                            return <View key={uuidv4()}></View>
                          }
                        }) : <View key={uuidv4()}></View>}
                      </Column>
                      <Column>
                        {podcast ? displayRandomPodcast().map((pod: any, index: number) => {
                          if (index % 2 === 1) {
                            return pod
                          } else {
                            return <View key={uuidv4()}></View>
                          }
                        }) : <View key={uuidv4()}></View>}
                      </Column>
                    </Row>
                  </Column>
                  <Column width="300px"><SideBar /></Column>
                </Row>
              </Row>
            </Column>
          </Row>
        </Row>
      </Row> */}

      {/* <Row flex="1">
        <View as="img" width="100%" height="40px" src={Rubalise}></View>
      </Row> */}
      {/* <Row flex="1" justifyContent="center">
      <Row width="100%" justifyContent="center" pb="2rem" maxWidth="1100px">
        <Column width="100%">
            <Column flex="1">
              <Text as="h1" m="1.5rem">INFOS</Text>
              <GreenHr />
            </Column>
          <Row>
            {
              news 
              ? 
              display()
              : 
              <Row flex="1" p="3rem" justifyContent="center"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Row>
            }
          </Row>
          <Row justifyContent="center">
            <Link to="/infos" style={{ textDecoration: "none", color: "black" }}>
              {hovered && (hoveredId === 3) ? <button onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(3) }} style={{ backgroundColor: "#4E547B", borderStyle: "none", borderRadius: 5, width: "auto", padding: "1rem", cursor: "pointer" }} >
                <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16 }} color="white">Voir toutes les infos</Text>
              </button> :
                <button onClick={() => { }} onMouseLeave={() => { mouseLeave() }} onMouseEnter={() => { mouseEnter(3) }} style={{ backgroundColor: "#5DCD69", borderStyle: "none", borderRadius: 5, width: "auto", padding: "1rem", cursor: "pointer" }} >
                  <Text style={{ fontFamily: 'Barlow Semi Condensed', textTransform: 'uppercase', fontSize: 16 }} color="white">Voir toutes les infos</Text>
                </button>}
            </Link>

          </Row>
          
        </Column>
      </Row>

      </Row> */}


      {/* <Footer /> */}
    </Column>











  );
});

export default Home;
