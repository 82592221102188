import React, { useState, useRef, useEffect } from "react"
import { Row, Column, Text, View } from "../ui"
import ArrowLeft from "./images/ArrowLeft.png"
import ArrowLeftHover from "./images/ArrowLeftHover.png"
import ArrowRight from "./images/ArrowRight.png"
import ArrowRightHover from "./images/ArrowRightHover.png"
import Calendar from "./images/Calendar.png"
import useApp from "../providers/AppProvider"
import moment, { Moment } from "moment"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DateTimePicker, DatePicker } from "@material-ui/pickers"
import { Overrides } from '@material-ui/core/styles/overrides';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { createMuiTheme } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core"

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const materialTheme = createMuiTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#5DCD69",
        },
      },
      MuiPickerDTHeader : {
          header : {
          backgroundColor : "#5DCD69"
          }

      },
     
      MuiPickerDTTabs :{
          tabs : {
          backgroundColor : "#5DCD69"
          },
      }
      ,
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: "#5DCD69",
          // color: "white",
        },
      },
      MuiPickersClock : {
          pin : {
              backgroundColor : "#6C00FF"
          }
      },
      MuiPickersClockPointer : {
          pointer : {
              backgroundColor : "#6C00FF"
          }
      },

      MuiPickersClockNumber : {
          clockNumberSelected : {
              backgroundColor : "#6C00FF"
              
          },
          thumb : {
              backgroundColor : "#6C00FF" ,
              color : "#6C00FF" ,
              primaryColor : "#6C00FF",
              secondaryColor : "6C00FF"
          },
          noPoint : {
              backgroundColor : "#6C00FF"
          },

      },
      
      MuiPickersDay: {
        day: {
          color: "#000000",
        },
        daySelected: {
          backgroundColor: "#6C00FF",
        },
        dayDisabled: {
          color: "#050505",
        },
        current: {
          color: "#5DCD69",
        },
      },
      MuiPickersModal: {
        dialogAction: {
          color: "#5DCD69",
        },
      },
      MuiTabs : {
          indicator : {
            backgroundColor : "#6C00FF" ,
            borderColor : "#6C00FF"
          },
      },
      MuiInputBase: {
        input: {
          textTransform: 'uppercase',
          fonWeight: 'bold',
          color: "#5DCD69",
          textAlign: 'center',
          fontFamily: 'Barlow Condensed', 
          fontSize: '30px', 
          fontWeight: 700
        }
      },
      MuiInput: {
        underline: {
          '&:before': {
            borderBottom: 'none'
          }
        }
      }
    },
  });

type props = {
  onDateChanged: (date: string) => void
}
  
const ProgrammeSearch = React.memo<props>(({ onDateChanged }) => {

    const [selectedDate, setSelectedDate] = useState(moment());
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    const options = {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric'
    }


    const handleChange = (d : MaterialUiPickersDate) => { 
        setSelectedDate(moment(d));
    }

    useEffect(() => {
      onDateChanged(selectedDate.format('YYYY-MM-DD'));
    }, [selectedDate])

    return (
        <Row justifyContent="center" mt="40px" mb="40px">
            <Column>
            {hovered && (hoveredId === 1) ?
              <View mt="-6px" p="1rem" as="img" onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()} onClick={() => { setSelectedDate(moment(selectedDate.subtract(1 ,'days').toString()))}} width="17" height="30" cursor="pointer" src={ArrowLeftHover}></View> :
                <View mt="-6px" p="1rem" as="img" onMouseEnter={() => mouseEnter(1)} onMouseLeave={() => mouseLeave()} onClick={() => { setSelectedDate(moment(selectedDate.subtract(1 ,'days').toString()))}} width="17" height="30" cursor="pointer" src={ArrowLeft}></View>
                }
            </Column>

            
            <Column>
                <Row>
                <ThemeProvider theme={materialTheme}>
                  <DatePicker cancelLabel="Fermer" clearLabel="Effacer" value={selectedDate} onChange={(date : MaterialUiPickersDate) => handleChange(date)} format='DD MMMM' />
                </ThemeProvider>
                </Row>
            </Column>
            <Column>
            {hovered && (hoveredId === 2) ?
              <View mt="-6px" p="1rem" as="img" onMouseEnter={() => mouseEnter(2)} onMouseLeave={() => mouseLeave()} onClick={() => { setSelectedDate(moment(selectedDate.add(1 ,'days').toString()))}} width="17" height="30" cursor="pointer" src={ArrowRightHover}></View> :
                <View mt="-6px" p="1rem" as="img" onMouseEnter={() => mouseEnter(2)} onMouseLeave={() => mouseLeave()} onClick={() => { setSelectedDate(moment(selectedDate.add(1 ,'days').toString()))}} width="17" height="30" cursor="pointer" src={ArrowRight}></View>
                }
            </Column>
        </Row>
    )
});

export default ProgrammeSearch