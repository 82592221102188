import React, { useState } from "react"
import { Row, View, Text, Column } from "../ui"
import LogoWhite from "./images/LogoWhite.png"
import LogoPrimary from "./images/LogoPrimary.png"
import { Link } from "react-router-dom"

const BottomBar = () => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    return (
        <Row flex="1" p="2rem" justifyContent="center" backgroundColor="secondary">
            <Row width="1100px" justifyContent="flex-start" alignItems="center">

                    <Column style={{ marginLeft: "1rem", marginRight: "3rem" }}>
                        <Link to="/">
                            {hovered && hoveredId === 1 ?
                                <View
                                    as="img"
                                    src={LogoPrimary}
                                    width="150px"
                                    onMouseEnter={() => mouseEnter(1)}
                                    onMouseLeave={() => mouseLeave()}
                                ></View>
                                :
                                <View
                                    as="img"
                                    src={LogoWhite}
                                    width="150px"
                                    onMouseEnter={() => mouseEnter(1)}
                                    onMouseLeave={() => mouseLeave()}
                                ></View>
                            }
                        </Link>



                    </Column>
                    <Column style={{ marginLeft: "3rem", marginRight: "3rem" }}>
                        <Link style={{textDecoration: "none"}} to="/apropos">
                            {hovered && hoveredId === 2 ? <Text
                                as="p"
                                m="auto"
                                flex="1"
                                color="white"
                                onMouseEnter={() => mouseEnter(2)}
                                onMouseLeave={() => mouseLeave()}
                                style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                            >Qui Sommes Nous ?</Text>
                                :
                                <Text
                                    as="p"
                                    m="auto"
                                    flex="1"
                                    color="primary"
                                    onMouseEnter={() => mouseEnter(2)}
                                    onMouseLeave={() => mouseLeave()}
                                    style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                                >Qui Sommes Nous ?</Text>}
                        </Link>
                    </Column>
                    {/* <Column flex="1" p="1rem">
                        <Link style={{textDecoration: "none"}} to='/partenaires'>
                            {hovered && hoveredId === 3 ? <Text
                                as="p"
                                m="auto"
                                flex="1"
                                color="white"
                                onMouseEnter={() => mouseEnter(3)}
                                onMouseLeave={() => mouseLeave()}
                            >Partenaires</Text>
                                :
                                <Text
                                    as="p"
                                    m="auto"
                                    flex="1"
                                    color="primary"
                                    onMouseEnter={() => mouseEnter(3)}
                                    onMouseLeave={() => mouseLeave()}
                                >Partenaires</Text>}
                        </Link>
                    </Column> */}
                    <Column style={{ marginLeft: "3rem", marginRight: "3rem" }}>
                        <Link style={{textDecoration: "none"}} to="/contact">
                            {hovered && hoveredId === 4 ? <Text
                                as="p"
                                m="auto"
                                flex="1"
                                color="white"
                                onMouseEnter={() => mouseEnter(4)}
                                onMouseLeave={() => mouseLeave()}
                                style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                            >Contact</Text>
                                :
                                <Text
                                    as="p"
                                    m="auto"
                                    flex="1"
                                    color="primary"
                                    onMouseEnter={() => mouseEnter(4)}
                                    onMouseLeave={() => mouseLeave()}
                                    style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                                >Contact</Text>}
                        </Link>
                    </Column>
                    <Column style={{ marginLeft: "3rem", marginRight: "3rem" }}>
                        <Link style={{textDecoration: "none"}} to="/legal">
                            {hovered && hoveredId === 5 ? <Text
                                as="p"
                                m="auto"
                                flex="1"
                                color="white"
                                onMouseEnter={() => mouseEnter(5)}
                                onMouseLeave={() => mouseLeave()}
                                style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                            >Mentions Légales</Text>
                                :
                                <Text
                                    as="p"
                                    m="auto"
                                    flex="1"
                                    color="primary"
                                    onMouseEnter={() => mouseEnter(5)}
                                    onMouseLeave={() => mouseLeave()}
                                    style={{ fontFamily: 'Barlow Semi Condensed', fontSize: '20px', fontWeight: 500 }}
                                >Mentions Légales</Text>}
                        </Link>
                    </Column>
            </Row>

        </Row>


    )
}



export default BottomBar