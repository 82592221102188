import * as React from "react";
import { ThemeProvider as BaseThemeProvider } from "styled-components";
import theme from "./theme";

type Props = {
  children: React.ReactNode;
};

const ThemeProvider = ({ children }: Props) => (
  <BaseThemeProvider theme={theme}>{children}</BaseThemeProvider>
);

export default ThemeProvider;
