import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Row, Column, Text, View } from "../ui"
import Music from "./images/Music.png"
import MusicHover from "./images/MusicHover.png"
import useApp from "../providers/AppProvider"


const WidgetTitres = () => {
    const {setPage} = useApp();
    const [hovered , setHovered] = useState(false);
    return (
        <Column justifyContent="center" alignItems="flex-start">
            <Row backgroundColor="primary" style={{ borderLeftWidth: "1px", borderLeftColor: '#272728', borderLeftStyle: 'solid' }}>
                <Link style={{ textDecoration: "none", color: "white", height: "auto" }} to="/titresdiffuses">
                    { hovered? <Row onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} p="1rem" justifyContent="center" alignItems="center" onClick={() => {setPage("titres")}}>
                        <View as="img" src={MusicHover} width="12px" height="12px" style={{ marginRight: "10px", marginTop: "4px" }}></View>
                        <Text style={{ fontFamily: 'Barlow', fontSize: 16, fontWeight: 500 , color: "black" }}>TITRES DIFFUSÉS</Text> </Row>
                         : <Row onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} p="1rem" justifyContent="center" alignItems="center" onClick={() => {setPage("titres")}}>
                        <View as="img" src={Music} width="12px" height="12px" style={{ marginRight: "10px", marginTop: "4px" }}></View>
                        <Text style={{ fontFamily: 'Barlow', fontSize: 16, fontWeight: 500 }}>TITRES DIFFUSÉS</Text>
                    </Row>}
                </Link>
            </Row>
        </Column>
    )
}

export default WidgetTitres