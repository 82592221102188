import React, { memo, useState, useEffect } from "react"
import { Column, Text, View, Row } from "../ui"

type props = {
    type : string ,
    title : string , 
    onClick : (e : React.MouseEvent) => void
}


const MenuItem = memo<props>(({type ,  title , onClick }) => {
    const [hover , setHover] = useState(false);

    const activeDisplay = <Column p="1rem" onMouseEnter={() => {setHover(true)}} onMouseLeave={() => {setHover(false)}}>
        <Text cursor="pointer"  onClick={(e) => {onClick(e)}} fontWeight="bold" style={{ color: "#6C00FF" }}>{title}</Text>
        <View as="div" width="100%" height="3px" backgroundColor="primary"></View>
    </Column>

    const Display = <Column p="1rem" onMouseEnter={() => {setHover(true) }} onMouseLeave={() => {setHover(false)}}>
        <Text cursor="pointer" onClick={(e) => {onClick(e) }} fontWeight="bold" style={{ color: "#616164" }}>{title}</Text>
    </Column>

    return (
        hover || type == "active" ? activeDisplay  : Display
    )
})


export default MenuItem