import React, { useState } from "react"
import { Row, Column, Text, View } from "../ui"
import Nouveau from "./images/wr-nouveautes.png"
import NouveauHover from "./images/wr-nouveautes-over.png"
import Nuit from "./images/wr-nuit.png"
import NuitHover from "./images/wr-nuit-over.png"
import Messe from "./images/wr-messe.png"
import MesseHover from "./images/wr-messe-over.png"
import useApp from "../providers/AppProvider"



const UpperFooter = () => {

    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);
    const {setPlayerLink , setPlayerArtist , setPlayerImage , setPlayerTitle ,setPlayerWebRadio} = useApp();

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    return (
        <Row backgroundColor="secondary" height="300px" justifyContent="space-around">
            <Row width="1100px" justifyContent="space-between" alignItems="center" style={{ height: "100%" }}>
                <Column p="1rem" justifyContent="center" style={{ marginTop: "10px", marginRight: "50px" }}>
                    <Text style={{ fontFamily: 'Barlow Condensed',fontWeight : 500, fontSize: '38px', color: 'white' }}>WEB RADIOS</Text>
                </Column>
                <Column style={{ height: "300px" }} 
                    alignItems="center" 
                    justifyContent="center" 
                    cursor="pointer" 
                    onClick={() => {setPlayerLink('https://www.radioking.com/play/lechantier-lesnouveautesdumoment/373765') ; setPlayerArtist("Web Radio") ; setPlayerTitle("Les Nouveautes du moment") ; setPlayerImage("https://www.radioking.com/api/track/cover/5338e24c-f007-4b45-b1bc-4558b6df4b3f") ; setPlayerWebRadio('Les Nouveautés du moment')}} >
                    {
                        hovered && hoveredId === 1 ?
                            <Column
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={NouveauHover}
                                onMouseEnter={() => mouseEnter(1)}
                                onMouseLeave={() => mouseLeave()}
                            ></Column>
                            :
                            <Column
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={Nouveau}
                                onMouseEnter={() => mouseEnter(1)}
                                onMouseLeave={() => mouseLeave()}
                            ></Column>
                    }
                </Column>
                <Column p="1rem" alignItems='center' cursor="pointer" onClick={() => {setPlayerLink('https://www.radioking.com/play/lechantier-nuittumefaispeur/373756'); setPlayerArtist("Web Radio") ; setPlayerTitle("Nuit Tu Me Fais Peur") ; setPlayerImage("https://www.radioking.com/api/track/cover/4a15b00c-a769-4d11-9a74-1aaf0b1e9cd5"); setPlayerWebRadio('Nuit tu me fais peur')}}>
                    {
                        hovered && hoveredId === 2 ?
                            <View
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={NuitHover}
                                onMouseEnter={() => mouseEnter(2)}
                                onMouseLeave={() => mouseLeave()}
                            ></View>
                            :
                            <View
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={Nuit}
                                onMouseEnter={() => mouseEnter(2)}
                                onMouseLeave={() => mouseLeave()}
                            ></View>
                    }
                </Column>
                <Column p="1rem" alignItems="center" cursor="pointer" onClick={() => {setPlayerLink('https://www.radioking.com/play/lechantier-lamessedeminuit/373774');setPlayerArtist("Web Radio") ; setPlayerTitle("La Messe De Minuit"); setPlayerImage('https://www.radioking.com/api/track/cover/4b907c5f-8d11-4618-801d-053ba94385f3') ; setPlayerWebRadio('La messe de minuit')}}>
                    {
                        hovered && hoveredId === 3 ?
                            <View
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={MesseHover}
                                onMouseEnter={() => mouseEnter(3)}
                                onMouseLeave={() => mouseLeave()}
                            ></View>
                            :
                            <View
                                width="200px"
                                height="auto"
                                justifyContent="center"
                                as="img"
                                backgroundSize="contain"
                                src={Messe}
                                onMouseEnter={() => mouseEnter(3)}
                                onMouseLeave={() => mouseLeave()}
                            ></View>
                    }
                </Column>
            </Row>
        </Row>
    )
}




export default UpperFooter