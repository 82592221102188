import React, { useEffect, useState } from "react"
import { useParams, Link, useHistory } from "react-router-dom";
import CopyToClipboard from 'react-copy-to-clipboard';
import Pagination from "@material-ui/lab/Pagination"
import {v4 as uuidv4} from "uuid"
import { Text, Column, Row, Button, View } from "../ui"
import moment, { Moment } from "moment"
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { FacebookShareButton, TwitterShareButton } from "react-share"
import facebookIcon from "./images/share_facebook.png"
import twitterIcon from "./images/share_twitter.png"
import emailIcon from "./images/share_email.png"
import linkIcon from "./images/share_link.png"
import Spinner from "react-spinner-material";
import useApp from "../providers/AppProvider";
import playhover from "./images/PlayGrayHover.png"
import play from "./images/PlayGray.png"
import LoadingInfos from "./LoadingInfos";
import "../index.css"
import ProgrammeCard from "./ProgrammeCard";
import ProgrammeCardMobile from "./ProgrammeCardMobile";

const displayBr = (array: any) => {
    return array.map((el: any) => {

        //console.log('DISPLAY BR TYPE', typeof(el));
        if (typeof (el) === "string") {
            return el
        }
        else if (typeof (el) === "object" && el.type === "a") {
            return <View as="a" className="link"  href={el.props.href} target="_blank">{el.props.children}</View>
        }
        else if (typeof (el) === "object" && el.type === "strong") {
            return <strong>{el.props.children}</strong>
        }
        else if (typeof (el) === "object" && el.type === "em") {
            return <em>{el.props.children}</em>
        }
        else if (typeof (el) === "object" && el.type === "span") {
            return <span style={el.props.style}>{el.props.children}</span>
        }

        else {
            return <br />
        }
    })
}

const displayParagraph = (array: any) => {
    // console.log(array);
    let feed: any = [];
    for (let i = 1; i < array.length - 1; i++) {
        if (array[i].type === "iframe") {
            feed.push(
                <Row flex="1" justifyContent="center">
                    <iframe src={array[i].props.src} width="560" height="400"></iframe>
                </Row>
            )
        }
        else if (array[i].props && array[i].type !== "iframe") {
            let el = array[i].props.children
            if (el.props && el.props.children) {

                if (Array.isArray(el.props.children)) {
                    el.props.children.map((obj: any) => {
                        // console.log({ obj: obj.type })

                        if (obj.type === "iframe") {
                            feed.push(
                                <Row flex="1" justifyContent="center" style={{ overflow: "hidden", paddingTop: "56.25%", position: "relative" }}>
                                    <iframe style={{ borderStyle: "none", position: "absolute", left: 0, top: 0 }} src={obj.props.src} height={"100%"} width={"100%"}></iframe>
                                </Row>
                            )
                        }
                        else if (obj.type === "undefined" || obj.type === "br") {
                            feed.push(<></>)
                        }
                    })
                }
            }
            if (array[i].props.children.type === "img") {
                feed.push(
                    <Row flex="1" justifyContent="center">
                        <View
                            as="div"
                            style={{ width: '100%', height: "50vw", maxHeight: "400px", backgroundImage: `url(${array[i].props.children.props.src})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}>
                        </View>
                    </Row>
                )
            } else {
                // console.log(array[i].props.children)
                feed.push(
                    <Text as={array[i].type}>
                        {
                            array[i].props.children.type === "em" || array[i].props.children.type === "strong"
                                ?
                                array[i].props.children
                                :
                                Array.isArray(array[i].props.children) ?
                                    <Text as={array[i].type} >{displayBr(array[i].props.children)}</Text>
                                    :
                                    array[i].props.children.type === "a" ?
                                        <a className="link" href={array[i].props.children.props.href}>{array[i].props.children.props.children}</a>
                                        :
                                        array[i].props.children.type === "span" && array[i].props.children.props.style ?
                                            <Text as="p" style={array[i].props.children.props.style}>{array[i].props.children.props.children}</Text>
                                            :
                                            typeof (array[i].props.children) !== "string" ?
                                                <></>
                                                :
                                                decodeHtml(array[i].props.children)
                        }
                    </Text>

                )
            }

        }
        else {

        }
    }
    
    return feed;

}

const decodeHtml = (html: string) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const stripHtml = (htmlString : string) =>{
    return htmlString.replace(/<[^>]+>/g, '');
}

const minutesToString = (minutes: number) => {
    if (minutes < 60) {
        return minutes + "'";
    }
    let hours = minutes / 60;
    let min = minutes % 60;
    let minutesDisplay = "";
    if(min < 10 ){
        minutesDisplay = "0" + min;
    }
    return hours + "H" + minutesDisplay;
}

const displayEmissions = (events: any) => {
    return (
        <Column justifyContent="flex-start" alignItems="flex-start" flex="1" >
            {
                events.map((event :any) => {
                    let dateStart = moment(event.start_date);
                    let dateEnd = moment(event.end_date);
                    let content = stripHtml(event.description).split("|");
                    let podcast = event.venue ? event.venue.venue : "LE CHANTIER";
                    let guests = event.organizer.map((org:any) => {
                        return org.organizer;
                    });
                    let description = stripHtml(content[0]);
                    let link = event.website;
                    // let duration = dateEnd.diff(dateStart);
                    let duration = moment.duration(dateEnd.diff(dateStart));
                    var minutes = duration.asMinutes();

                    if (window.innerWidth < 1100)
                    {
                        return <ProgrammeCardMobile
                            title={decodeHtml(event.title)}
                            time={moment(dateStart).format('DD/MM/YYYY')}
                            guests={decodeHtml(guests.join(', '))}
                            podcast={decodeHtml(podcast)}
                            description={decodeHtml(stripHtml(description))}
                            duration={minutesToString(minutes)}
                            link={link}
                            key={uuidv4()}
                        />
                    }
                    else {
                        return <ProgrammeCard 
                                title={decodeHtml(event.title)}
                                time={moment(dateStart).format('DD/MM/YYYY')}
                                guests={decodeHtml(guests.join(', '))}
                                podcast={decodeHtml(podcast)}
                                description={decodeHtml(stripHtml(description))}
                                duration={minutesToString(minutes)}
                                link={link}
                                key={uuidv4()}
                        />
                    }
                } 
                )
            }
            </Column>
     )
}



const EmissionDisplay = () => {
    const [link, setLink] = useState<any>();
    const [article, setArticle] = useState<any>();
    const [linkCopied, setLinkCopied] = useState<boolean>(false);
    const { setPlayerLink, setPlayerImage, setPlayerArtist, setPlayerTitle, setPlayerWebRadio } = useApp()
    const [image, setImage] = useState<string>('https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc')
    const [artist, setArtist] = useState<string>('')
    const [title, setTitle] = useState<string>('')
    const [hovered, setHovered] = useState(false)
    const [url, setUrl] = useState<string>()
    const [og, setOg] = useState<any>();
    const [events , setEvents] = useState<any>();
    const [loading , setLoading] = useState<boolean>(true);
    let { id } = useParams<any>();
    const [pageCount, setPageCount] = useState<number>();
    const [pageNb, setPageNb] = useState<number>(1);

    let history = useHistory();
    const stripHtml = (htmlString: string) => {
        return htmlString.replace(/<[^>]+>/g, '');
    }

    const resolveStreamingLink = (url: string, label: string) => {
        let client_id = "60aa68f23f08ed052f078038527013fe"
        let reqUrl = "https://api.soundcloud.com/resolve?url=" + encodeURIComponent(url) + "&client_id=" + client_id;
        fetch(reqUrl)
            .then((response) => { return response.json() })
            .then((data) => { setImage(data.artwork_url ? data.artwork_url : data.user.avatar_url); setTitle(data.title); setArtist(data.user.username); setLink({ audio_link: data.stream_url + "?client_id=" + client_id, audio_link_label: label });  }

            )
    }

    const fetchEvents = async () => {
        setLoading(true);
        let urlAll = `https://wp.lechantier.radio/wp-json/tribe/events/v1/events?per_page=1000000&start_date=2021-01-01&categories=${id}`;
        const response:Response = await fetch(urlAll);
        const responseData:any = await response.json();

        if (responseData && responseData.events) {
            console.log('LENGTH', responseData.events.length);
            setPageCount(responseData.events.length / 10 === 0 ? 1 : Math.floor((responseData.events.length / 10) + (responseData.events.length % 10 > 0 ? 1 : 0))); 
        }
        let url = `https://wp.lechantier.radio/wp-json/tribe/events/v1/events?per_page=10&page=${pageNb}&start_date=2021-01-01&categories=${id}`;
        console.log('FETCH URL', url)
        fetch(url)
        .then((response) => {return response.json()})
        .then((data) => {
            if (data.events && data.events.length > 0) {
                let currentDate:string = '';
                let linksOfTheDay:Array<any> = [];
                let eventsToDisplay:Array<any> = [];

                data.events.map((item: any) => {
                    if (currentDate != moment(item.start_date).format('DD/MM/YYYY')) {
                        linksOfTheDay = [];
                        currentDate = moment(item.start_date).format('DD/MM/YYYY');
                    }
                    if (linksOfTheDay.indexOf(item.website) == -1) {
                        linksOfTheDay.push(item.website);
                        eventsToDisplay.push(item);
                    }
                })
                setEvents(eventsToDisplay)
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        let url = "https://wp.lechantier.radio/wp-json/wp/v2/posts/?slug=" + id
        fetch(url)
            .then((response) => { return response.json() })
            .then((data) => {
                if (data.length > 0) {
                    // console.log({ article: data[0].content }); 
                    setArticle({ "title": data[0].title.rendered, "date": data[0].date, "excerpt": parse(data[0].content.rendered) });
                    let parsedHTML: any = parse(data[0].content.rendered);
                    let srcUrl: string = "";
                    for (let i = 0; i < parsedHTML.length - 1; i++) {
                        if (parsedHTML[i].props) {
                            if (parsedHTML[i].props.children.type === "img") {
                                if (srcUrl === "") {
                                    srcUrl = parsedHTML[i].props.children.props.src;
                                }


                            } else {


                            }
                        }
                    }
                    if (srcUrl === "") {
                        srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
                    }
                    setOg({ url: window.location.href, type: "article", image: srcUrl, title: data[0].title.rendered, description: data[0].excerpt.rendered.replace(/<[^>]*>?/gm, '') })
                    if (data[0].acf && data[0].acf.audio_link) {
                        if (data[0].acf.audio_link.includes('soundcloud')) {
                            resolveStreamingLink(data[0].acf.audio_link, data[0].acf.audio_link_label);
                        } else {
                            setLink(data[0].acf)
                        }
                    } else {
                        // console.log('no acf')
                    }
                } else {
                    history.push("/error");
                }
            })

    }, [])

    useEffect(() => {
        fetchEvents();
    }, []);

    useEffect(() => {
        fetchEvents();
    }, [pageNb])

    useEffect(() => {
        if (og) {
            fetch("https://wp.lechantier.radio/api/index.php", {
                method: "POST",
                body: JSON.stringify(og),

            })
                .then(response => response.json())
                .then(data => setUrl(data.url))

        }
    }, [og, id])

    useEffect(() => {
        if (linkCopied) {
            setTimeout(() => {
                setLinkCopied(false);
            }, 2000);
        }
    }, [linkCopied])

    return (
        <Row flex="1" style={{ width: '100%' }}>
            <Column textAlign="justify" style={{ width: '100%' }}>
                <Row flex="1" justifyContent="flex-start" style={{ width: '100%' }}>
                    <Column style={{ width: '100%' }}>
                        {
                            article && url
                                ?
                                <Column style={{ width: '100%', padding: "1rem", boxSizing: 'border-box' }}>
                                    <Text as="h1" style={{color : 'black'}}>{decodeHtml(article.title)}</Text>
                                    {
                                        article.excerpt.length >= 1
                                        ?
                                        displayParagraph(article.excerpt)
                                        :
                                        <></>
                                    }
                                </Column>
                                :
                                <LoadingInfos />
                        }
                        {
                            events && !loading
                            ?
                            displayEmissions(events)
                            :
                            <Row flex="1" p="3rem" justifyContent="center"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Row>
                        }
                        {
                            pageCount && pageNb !== null 
                            ?
                            <Row flex="1" p="3rem" justifyContent="center">
                                <Pagination count={pageCount} page={pageNb} onChange={(event, value) => { setPageNb(value); window.scrollTo(0, 300) }} />
                            </Row>
                            :
                            <></>
                        }
                    </Column>
                </Row>


            </Column>
        </Row>
    )

}



export default EmissionDisplay