import React, { memo, useState } from "react"
import { Link } from "react-router-dom";
import { Column, View, Text } from "../ui"
import { v4 as uuidv4 } from "uuid"
import VioletLink from "../ui/VioletLink";
import TitleCardLink from "../ui/TitleCardLink";


const decodeHtml = (html: string) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

type props = {
    src: string,
    title: string,
    podcastTitle: string,
    description: string,
    date?: string,
    podcastId?: number
}

const PodcastNewsCard = memo<props>(({ src, title, date, podcastTitle, description, podcastId }) => {
    return (
        <Column width="344px" p="0.5rem">
            <Column>
                <Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={"/podcasts/" + podcastId}>
                    <View
                        ml="auto"
                        mr="auto"
                        height="344px"
                        width="100%"
                        style={{ backgroundImage: `url(${src})`, backgroundSize: "cover", backgroundPosition: "center" }} >
                    </View>
                </Link>

            </Column>

            <Column pt=".5rem" >
                <Text m=".5px" as="p" fontWeight="bold" style={{ color: "#6C00FF" }}>{decodeHtml(podcastTitle)}</Text>
                <TitleCardLink to={"/podcasts/" + podcastId}>
                    {decodeHtml(title)}
                </TitleCardLink>
                <Text as="p" m=".5px" mt=".5rem" mb=".5rem" color="black" fontWeight="200">{date}</Text>
                <Text>{decodeHtml(description)}</Text>

                <VioletLink to={"/podcasts/" + podcastId}>
                    Lire la suite
                </VioletLink>
            </Column>

        </Column >
    )

})



export default PodcastNewsCard