import React, { useState } from "react"
import { useForm, Controller, FieldValues } from 'react-hook-form';
import TextField from "@material-ui/core/TextField";
// import { Column, View, Row, Text, Button } from "../ui"
import emailjs from "emailjs-com";
import { Column, Text, Row } from "../ui";
import { Button } from '@material-ui/core';
import Axios from 'axios';

const userID = "user_KctNvzwE5TVOfjsgdoNE4";


const Contact = () => {
  const { handleSubmit, errors, control, getValues, reset } = useForm();
  const [success, setSuccess] = useState<null|string>(null);
  const [error, setError] = useState<null|string>(null);

  const onSubmit = async (values:FieldValues) => {
    setSuccess(null);
    setError(null);
    try {
        const response = await Axios.post('https://le-chantier-radio-api.herokuapp.com/send', {
            name: values.name,
            email: values.email,
            message: values.message
        });

        if (response && response.status === 200) {
            setSuccess('Votre message a bien été envoyé. Nous faisons tout pour vous répondre rapidement.');
            reset();
        }
        else {
            setError('Une erreur est survenue. Veuillez réesayer plus tard');
        }
    }
    catch (e) {
        setError('Une erreur est survenue. Veuillez réesayer plus tard');
    }
  };

  return (
    <Column>
        <Text as="h1">Contactez-nous</Text>
        <Text as="h3">Une question ? Une demande ? C’est ici.</Text>
        <form onSubmit={handleSubmit(onSubmit)}>
            <label style={{ color: '#171D45' }}>Votre nom</label>
            <Controller
                name="name"
                defaultValue=""
                control={control}
                style={{ width: '100%', marginBottom: '20px' }}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                as={<TextField name="name" type="text" variant="outlined" />}
            />
            <label style={{ color: '#171D45' }}>Votre email</label>
            <Controller
                name="email"
                defaultValue=""
                control={control}
                style={{ width: '100%', marginBottom: '20px' }}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                as={<TextField name="email" type="email" variant="outlined" />}
            />
            <label style={{ color: '#171D45' }}>Votre message</label>
            <Controller
                name="message"
                defaultValue=""
                control={control}
                style={{ width: '100%', marginBottom: '20px' }}
                rules={{
                    required: "Ce champ est obligatoire"
                }}
                as={<TextField name="email" type="email" variant="outlined" multiline={true} rows={6} />}
            />
            <Row width="100%" justifyContent="flex-start">
                <Button type="submit" variant={'outlined'} style={{ backgroundColor: '#5DCD69', color: 'white' }}>Envoyer</Button>
            </Row>
        </form>
        {
            success
            ?
            <Text as="p" color="#5DCD69" fontWeight="bold">{success}</Text>
            :
            error
            ?
            <Text as="p" color="red" fontWeight="bold">{error}</Text>
            :
            null
        }
    </Column>
  );
}

export default Contact