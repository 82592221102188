import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { ThemeProvider, View ,Text } from "./ui";
import Layout from "./components/Layout";
import LayoutMobile from "./components/LayoutMobile";
import Home from "./scenes/Home";
import Podcast from "./scenes/Podcast"
import { AppProvider } from "./providers/AppProvider";
import {
  Link,
  Route,
  BrowserRouter as Router,
  Switch , 
  Redirect
} from "react-router-dom"
import Infos from "./scenes/Infos";
import Programme from "./scenes/Programme";
import HomeMobile from "./scenes/HomeMobile";
import PodcastMobile from "./scenes/PodcastMobile";
import InfosMobile from "./scenes/InfoMobile";
import ProgrammeMobile from "./scenes/ProgrammeMobile";
import InfosDisplay from "./components/InfosDisplay";
import TitreDiffuse from "./scenes/TitreDiffuse";
import TitreDiffuseMobile from "./scenes/TitreDiffuseMobile";
import Contact from "./scenes/Contact";
import WebRadio from "./scenes/WebRadio";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PodcastDisplay from "./components/PodcastDisplay";
import PodcastDisplayMobile from "./components/PodcastDisplayMobile";
import SearchResults from "./scenes/SearchResults";
import WebRadioMobile from "./scenes/WebRadioMobile";
import Legal from "./scenes/Legal";
import About from "./scenes/About";
import Display from "./components/Display";
import ScrollToTop from "./components/ScrollToTop";
import Error from "./scenes/Error"
import ReactGa from "react-ga"
import Emissions from "./scenes/Emissions";
import EmissionsMobile from "./scenes/EmissionsMobile";
import EmissionDisplay from "./components/EmissionDisplay";

function App() {

  const [size , setSize] = useState({width : 0, height :0});

  useEffect(()=>{
    ReactGa.initialize("UA-171390952-1")
    const moment = new MomentUtils({locale : "fr"})
    setSize({width : window.innerWidth , height : window.innerHeight})
    window.addEventListener('resize' , () => {
        setSize({width : window.innerWidth , height : window.innerHeight });
    })

  } , [])
  return (

    <ThemeProvider>
      <CookieConsent
        location="bottom"
        buttonText="J'ai compris"
        cookieName="cookies-accept-chantier"
        style={{ background: "#272728" }}
        buttonStyle={{ backgroundColor: "#5DCD69", color: 'white', fontSize: "16px" }}
        expires={150}
      >
        En poursuivant la navigation vous acceptez l'utilisation de cookies. Nous utilisons les cookies pour vous proposer la meilleure expérience possible.
      </CookieConsent>
      <AppProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <ScrollToTop>
            {size.width < 1100?
            <LayoutMobile>
               <Switch >
              <Route exact path="/">
                {ReactGa.pageview(window.location.pathname + window.location.search)}
                <HomeMobile />
              </Route>
              <Route exact path="/programmes">
                <ProgrammeMobile />
              </Route>
             <Route exact  path="/emissions/:id">
                <EmissionDisplay />
             </Route>
              <Route exact path="/podcasts">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <PodcastMobile />
              </Route>
              <Route exact path="/podcasts/:id">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <PodcastDisplayMobile />
              </Route>
              <Route exact path="/infos">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Infos />
              </Route>
              <Route exact path="/infos/:id">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <InfosDisplay />
              </Route>
              <Route exact path="/search/:word">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
               <SearchResults />
             </Route>
              <Route exact path="/webradios">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <WebRadioMobile />
              </Route>
              <Route exact path="/contact">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <Contact />
              </Route>
              <Route exact path="/apropos">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
                <About />
              </Route>
              {/* <Route exact path="/partenaires">
                <><p>partenaires</p></>
              </Route> */}
              <Route exact path="/legal">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Legal />
              </Route>
              <Route exact path="/titresdiffuses">
              {ReactGa.pageview(window.location.pathname + window.location.search)}
            <TitreDiffuseMobile />
             </Route>
             <Route path="/error">
               <Error />
             </Route>
             <Route path="*">
               <Error />
             </Route>
             </Switch>
            </LayoutMobile> : 
             <Display>
               <Switch>
             <Route exact path="/">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
              <Layout><Home /></Layout>
             </Route>
             <Route exact  path="/programmes">
               <Layout>
                <Programme />
               </Layout>
             </Route>
             <Route exact  path="/emissions/:id">
               <Layout>
                <EmissionDisplay />
               </Layout>
             </Route>
             <Route exact path="/podcasts">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout> <Podcast /></Layout>
              
             </Route>
             <Route exact path="/podcasts/:id">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
                <PodcastDisplay />
                </Layout>
              </Route>
             <Route exact path="/infos">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <Infos />
               </Layout>
             </Route>
             <Route exact  path="/infos/:id">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <InfosDisplay />
               </Layout>
              </Route>
             <Route exact path="/webradios">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
              <Layout><WebRadio /></Layout> 
             </Route>
             <Route exact  path="/contact">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout> <Contact /></Layout>
             
             </Route>
             <Route exact path="/apropos">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <About />
               </Layout>
             </Route>
             <Route exact path="/search/:word">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <SearchResults />
               </Layout>
               
             </Route>
             {/* <Route exact path="/partenaires">
             <Layout>
               <><p>partenaires</p></>
               </Layout>
             </Route> */}
             <Route exact path="/legal">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <Legal />
               </Layout>
              
             </Route>
             <Route exact path="/titresdiffuses">
             {ReactGa.pageview(window.location.pathname + window.location.search)}
               <Layout>
               <TitreDiffuse />
               </Layout>
             </Route>
             <Route path="/error" >
               <Layout>
               <Error />
               </Layout>
             </Route>
             <Route path="*" >
               <Layout>
               <Error />
               </Layout>
             </Route>
             </Switch>
           </Display>}
          </ScrollToTop>
        </Router>
        </MuiPickersUtilsProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
