const breakpoints = ["600px", "1200px"];

export const breakpointsAlias = {
  mobile: breakpoints[0],
  tablet: breakpoints[1],
  desktop: breakpoints[2],
};

export const colors = {
  blue: [
    "#010732", // 0
    "#e9f0ff", // 1
    "#1da1f2", //2
  ],
  yellow: [
    "#ffd200", // 0
  ],
  black: ["#000000", "#111111"],
  primary: "#5DCD69",
  secondary: "#272728", // #e6ff00 "#ffd200" #7fffd4
  link: "#1da1f2",
};

const theme = {
  breakpointsAlias,
  breakpoints,
  colors,
};

export default theme;
