import React from "react"
import { Column , View , Row } from "../ui"
import GreenHr from "./GreenHr"

const displayLoading = () => {
    let feed : any = []
    for(let i = 0 ; i < 10 ; i++){
    feed.push(
        <Row mb="20px" mt="20px" width="800px">
            <Column width="200px">
                <View as="div" width="200px" height="200px" style={{backgroundColor : 'whitesmoke'}}></View>
            </Column>
            <Column ml="20px">
            <View as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View  mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View  mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View  mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            <View  mt="10px" as="div" width="580px" height="20px" style={{backgroundColor : 'whitesmoke'}}></View>
            </Column>
        </Row>
    )}
    return feed
}
const LoadingCardPodcast = () => {
    return (

        <Column width="800px">
       
                            <Row>
                                <Column >
                                    <View
                                        as="div"
                                        style={{backgroundColor : "whitesmoke"}}
                                        width="800px"
                                        height="300px"
    
                                    ></View>
                                </Column>
                                </Row>
                               <Column mt="10px" width="800px">
                                
                                   <View as="div" mb="10px" height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                               
                                {/* <Row>
                                    <Text as="h4" m="0px" fontStyle="italic">
                                        {subtitle}
                                    </Text>
                                </Row> */}
                                
                                
                                <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    <View as="div" mb='10px' height='15px' width='100%' style={{backgroundColor : "whitesmoke"}}></View>
                                    
    
                            </Column>
    
                        
                        <GreenHr />
                        {displayLoading()}
                        
                        </Column>

    )
}

export default LoadingCardPodcast