import React, { memo, useEffect } from "react"
import { View } from "../ui"
import {Timeline} from "react-twitter-widgets"


type props = {
    height : number 
}
const TwitterTimeline = memo<props>(({height}) => {

    return (
        <View as="div" maxHeight={height} style={{overflow: "hidden"}}>
            <Timeline 
                dataSource={{
                    sourceType : "profile",
                    screenName : "le_chantier"
                }}
                options={{
                    height : 7000,
                    chrome : "noheader , nofooter, noscrollbar , transparent",
                    borderColor : "#5DCD69",
                    dnt: true
                    
                }}
            />
        
         </View>
    )
})



export default TwitterTimeline