import React, { useState, Props, memo } from "react"
import { Row, Column ,Text, View } from "../ui"
import PlayBtn from "./images/PlayGray.png"
import PlayBtnHover from "./images/PlayGrayHover.png"
import useApp from "../providers/AppProvider"
import { LivejournalIcon } from "react-share"


type props = {
    title : string ,
    podcast : string,
    duration : string, 
    description : string , 
    guests: string, 
    time: string,
    link: string
}

const ProgrammeCardMobile = memo<props>(({title , podcast , duration , description , guests ,time, link} ) => {
    const [isHovered , setIshovered ] = useState(false);
    const{ setPlayerLink ,setPlayerArtist , setPlayerImage , setPlayerTitle } = useApp();

    const resolveStreamingLink = (url: string) => {
        let client_id = "60aa68f23f08ed052f078038527013fe"
        let reqUrl = "https://api.soundcloud.com/resolve?url=" + encodeURIComponent(url) + "&client_id=" + client_id;
        fetch(reqUrl)
            .then((response) => { return response.json() })
            .then((data) => { setPlayerImage(data.artwork_url ? data.artwork_url : data.user.avatar_url); setPlayerTitle(data.title); setPlayerArtist(data.user.username); setPlayerLink(data.stream_url + "?client_id=" + client_id);  }

            )
    }

    const handleClick = () => {
        if (link.includes('soundcloud')) {
            resolveStreamingLink(link);
        }else {
            setPlayerLink(link);
        }
        setPlayerArtist(title);
        setPlayerTitle(podcast);
        setPlayerImage("https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc")
    }


    const play = <View as="img" onClick={() => {handleClick()}} cursor="pointer" width="50px" height="50px" src={PlayBtn} onMouseEnter={() => setIshovered(true)} onMouseLeave={() => setIshovered(false)}></View>
    const playhovered = <View onClick={() => {handleClick()}} cursor="pointer" as="img" width="50px" height="50px" src={PlayBtnHover} onMouseEnter={() => setIshovered(true)} onMouseLeave={() => setIshovered(false)} ></View>
    return(
    <Column width="100%" alignItems="flex-start" mb="1rem">
        <Row alignItems="center">
            <Column pr="1rem" pl="1rem">
                <Text>{time}</Text>
            </Column>
            <Column>
                { isHovered ? playhovered : play }
            </Column>
        </Row>
        <Column p="1rem">
            <Text m="0px" as="h3" style={{ fontFamily: 'Barlow Condensed', fontSize: '30px', fontWeight: 700 }}>{title}</Text>
            <Row mt="12px" mb="12px">
            <Text fontWeight="bold" m="0px" style={{color: "#6C00FF", fontFamily: 'Barlow', fontSize: '16px', fontWeight: 700 }}>{podcast}</Text>
            <Text pl="1rem" m="0px" style={{ fontFamily: 'Barlow', fontSize: '16px', fontWeight: 300 }}>{duration}</Text>
            </Row>
            <Row pb="6px" mb="12px">
                <Text color="primary" style={{ fontFamily: 'Barlow Condensed', fontSize: '18px', fontWeight: 600 }}>Avec : </Text>
                <Text pl="6px" style={{ fontFamily: 'Barlow Condensed', fontSize: '18px', fontWeight: 500 }}>{guests}</Text>
            </Row>
            <Row>
                <Column>
                <Text m="0px">{description}</Text>
                </Column>
            
            </Row>
        </Column>
    </Column>
    )
})



export default ProgrammeCardMobile