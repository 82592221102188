import React from "react" 
import { Column , Text, Row } from "../ui"
import WebradioCard from "../components/WebradioCard"
import nouveautesImage from '../components/images/web_radio_nouveautes.jpg';
import messeMinuitImage from '../components/images/web_radio_messe_minuit.jpg';
import nuitPeurImage from '../components/images/web_radio_nuit_peur.jpg';

const WebRadioMobile = () => {
    return (
        
            <Column>
                <WebradioCard 
                playlist ="https://open.spotify.com/playlist/2v3AA5sx7FexIqoUTTG32M"
                episodeTitle={"Web Radio"} 
                image={nouveautesImage}
                description="Le meilleur du moment, le moment du meilleur. Dans cette sélection constamment renouvelée, retrouvez uniquement des morceaux que votre primeur va vous envier. Un flux éclectique, du contenu exclusif (avant-premières, cartes blanches…), c'est ici que seront diffusés l'intégralité des maxis et albums coups de cœur du Chantier." 
                episodeLink="https://www.radioking.com/play/lechantier-lesnouveautesdumoment/373765"  
                podcastName="Les Nouveautés du moment" />
                <WebradioCard 
                playlist ="https://open.spotify.com/playlist/1FqDWq7i8HP04CnZmy4ngP"
                episodeTitle="Web Radio" 
                episodeLink={"https://www.radioking.com/play/lechantier-nuittumefaispeur/373756"} 
                podcastName="Nuit tu me fais peur" 
                image={nuitPeurImage}
                description="Laissez-vous porter par la lune. Et par la sélection toute en élégance et fluidité de notre indolent programmateur. Espace de libre expression singulier à la ligne musicale climatique, ne soyez pas surpris si vous rencontrez des créations sonores expérimentales ou des happenings non-identifiés de plus de 20 minutes. Tout est parfaitement sous contrôle !" />
                <WebradioCard 
                playlist ="https://open.spotify.com/playlist/5Jsco0nDhPBCEzROzilGoJ"
                episodeTitle="Web Radio" 
                episodeLink={"https://www.radioking.com/play/lechantier-lamessedeminuit/373774"} 
                podcastName="La messe de minuit" 
                image={messeMinuitImage}
                description="Le meilleur de la musique solide ! Retrouvez ici une sélection inspirée de La messe de minuit, diffusée chaque jour de minuit à 1h sur Le Chantier. C'est le rendez-vous incontournable des musiques extrêmes et pas uniquement métal ! Une scène foisonnante, engagée et hyperactive... Comment aurions-nous pu nous en passer ?" />
            </Column>
        
    )
}


export default WebRadioMobile