import React, { memo, useState, useEffect } from "react"
import { Text, Column } from '../ui'
import useApp from "../providers/AppProvider";
import Podcast from "../scenes/Podcast";


type props = {
    array: any;
    podcast : string;
}

const episodeToString = (array: any) => {
    let length = array.length;
    return ['Episode ' + (length - 1), "Episode " + (length - 2), "Episode " + (length - 3)]

}


const EpisodeClickCard = memo<props>(({ array , podcast }) => {
   
    const { setPlayerLink, setPlayerArtist, setPlayerImage, setPlayerTitle  ,setPlayerWebRadio} = useApp()
    
    useEffect(() => {
     
    } ,[])

    const  feed = array.map((ep : any , index : number) => {
        return <Text key={index} cursor="pointer" onClick={() => {setPlayerLink(ep.enclosure.link) ; setPlayerArtist(podcast) ; setPlayerWebRadio('') ; setPlayerImage(ep.thumbnail) ; setPlayerTitle(ep.title)}}>{ep.title}</Text>

    })

    return <Column>
       {array? feed.slice(0,3)   : <></>}

    </Column>

})



export default EpisodeClickCard