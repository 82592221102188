import React, { useEffect, useState, memo } from "react"
import GreenHr from "../components/GreenHr"
import PodcastCardLarge from "../components/PodcastCardLarge"
import { Column, Text, Row } from "../ui"
import SideBar from "../components/SideBar"
import Spinner from "react-spinner-material"


import {v4 as uuidv4} from "uuid"
import { Link, useLocation } from "react-router-dom"
import useApp from "../providers/AppProvider"
import LoadingCardRow from "../components/LoadingCardRow"

const displayLoading = () => {
    let feed : any = [];
    for(let i = 0 ; i < 6 ; i++){
        feed.push(<LoadingCardRow />)
    }
    return feed
}

const Podcast = memo(() => {
   const [episodes, setEpisodes] = useState<any>();
    const [podcast, setPodcast] = useState<any>([]);
    const [condition , setCondition] = useState(false);
    const {setPage} = useApp();
   

let feed;
let allpod; 
let allEp;


    const episodeToString = (array : any) => {
        let length = array.length;
        return ['Episode '+(length -1) , "Episode "+(length-2), "Episode "+(length -3) ]

    }

  
    const stripHtml = (htmlString : string) =>{
        return htmlString.replace(/<[^>]+>/g, '');
      }


    useEffect(() => {
        
        const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
        
        fetch(("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=2"))
        .then((response) => {return response.json()})
        .then((data) => {
            let allPodcasts : any =  [];
            data.map((pod : any ) => {
                allPodcasts.push({id : pod.id  , link : stripHtml(pod.content.rendered)});
            }) 
            let tmp : Array<any> = [];
            // console.log(allPodcasts);
              allPodcasts.map(  (pod : any ,index : number) => {
                 fetch(ApiString+pod.link).then((response) => {return response.json()}).then((data) => {tmp[index] = {id : pod.id ,feed : data}} )
                 .then(() => {
                    //  console.log(tmp.length , allPodcasts.length)
                     if(tmp.length === allPodcasts.length)
                     {
                         setCondition(true);
                         setPodcast( tmp)
                    }
                })
            })
            
           
        })
        
        
    }, [])




    return (
        <Row flex="1"  >
            <Column width="100%">
                <Row width="100%">
                <Text as="h1" m="1.5rem">PODCASTS</Text>
                </Row>
                <GreenHr />
                
               
                 {/* {podcast ? <PodcastCardLarge title={podcast.title} image={podcast.image} content={podcast.description} subtitle={podcast.author} /> : <p>Loading</p>} */}

                {/* <PodcastCardLarge
                    title="Cartels"
                    subtitle="De l’art, un mot, un face à face !"
                    content="Chaque semaine, retrouvez Rémy et Martin dans un podcast
                     dédié aux mécaniques de la construction de l’information. Cette émission va vous
                      donner tout le biscuit nécessaire pour comprendre
                       les subtilités de la fabrique de l’actualité."
                    image="https://image.ausha.co/n50LCghpHkFu97rgFqfSvnUmeVTDgox5O9XNvS2b_1400x1400.jpeg?t=1586972190"
                    episodes={["Episode 8", "Episode 9", "Episode 10"]}

                /> */}
                 {
                    condition
                    ? 
                    allpod = podcast.map((pod :any) => {
                        // console.log('PODCAST', pod);
                        return  <PodcastCardLarge
                                    key={uuidv4()+Math.floor(Math.random()*10)}
                                    title={pod.feed.feed.title}
                                    image={pod.feed.feed.image}
                                    subtitle={pod.feed.feed.author}
                                    episodes={pod.feed.items}
                                    content={pod.feed.feed.description}
                                    noDetail={true}
                                    id={pod.id}
                                />
                    })
                    : 
                    displayLoading()
                }
                

                 {/* {condition? allEp = podcast.map((pod : any) => {
                     
                     let tmpEp = pod.items.map((ep :any) => {
                        let episodenumber = ep.title.replace(/[^0-9]/g, "");
                         
                        return <PodcastEpisodeCard 
                         image={ep.thumbnail} 
                         episode={episodenumber} 
                         duration={ep.enclosure.duration}
                         episodeLink={ep.enclosure.link}
                         episodeTitle={ep.title}
                         podcastName={pod.feed.title}
                         key={uuidv4()}
                         description={stripHtml(ep.description)}
                          />
                         


                     })
                     return tmpEp
                 }) : <Row flex="1" p="3rem" justifyContent="center"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Row>} */}
                

                {/* {episodes ? feed = episodes.map((episode : any) => {
                    let episodenumber = episode.title.replace(/[^0-9]/g, "");
                    return <PodcastEpisodeCard
                        episodeLink={episode.enclosure.link}
                        image={episode.thumbnail}
                        description={stripHtml(episode.content)}
                        episodeTitle={episode.title}
                        podcastName={podcast.title}
                        episode={episodenumber}
                        duration={episode.enclosure.duration}
                        key={episode.guid}
                    />
                })
                :<p>Loading</p>}   */}
                



            

            </Column>
            
            
        </Row>
    )

})

export default Podcast 