import React from "react"
import { Column } from "../ui"
import SocialsFooter from "./SocialsFooter"
import BottomBar from "./BottomBar"
import UpperFooter from "./UpperFooter"

const Footer = () =>  {
    return(
        <Column>
            <UpperFooter />
            <SocialsFooter />
            <BottomBar />
            
        </Column>
    )
}






export default Footer