import React, { memo, useState, useEffect } from "react"
import { Row, Text, Column, View } from "../ui"
import GreenHr from "./GreenHr"
import Playbtn from "./images/PLAY.png"
import useApp from "../providers/AppProvider"
import Spotify from "./images/SpotifyGrey.png"


type props = {
    image : string ,
    title : string ,
    subtitle? : string ,
    content? : string , 
    link : string ,
    playlist : string 
  
    

}

const stripHtml = (htmlString : string) =>{
    return htmlString.replace(/<[^>]+>/g, '');
  }

let feed ;
const WebRadioStation =memo<props>(({image , title , subtitle , content , link  , playlist}) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(0);
    const { setPlayerLink , setPlayerArtist , setPlayerImage , setPlayerTitle , setPlayerWebRadio}  = useApp()

  
  const handleClick = () => {
      setPlayerLink(link);
      setPlayerArtist('Web Radio');
      setPlayerTitle(title);
      setPlayerImage(image);
      setPlayerWebRadio(title);
  }
  
    
    
    return (
        <Column>
        <Row p="1rem" width="100%" m="auto" >
            <Column p="1rem">
               <View onClick={() => handleClick()}   as="div" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} width="300px" height="300px" style={{backgroundImage : `url(${image})` , backgroundSize : "cover" , cursor : "pointer" }}>
               {hovered? <View as="div" width="300px" height="300px" style={{backgroundColor : "rgba(0,0,0,.4)" ,position : "relative"}} > <View
                        style={{ transition: "ease-in .1s" }}
                        as="div"
                        height="50px"
                        width="50px"
                        m="auto"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                    >
                        <View
                            as="img"
                            src={Playbtn}
                            height="50px"
                            width="50px"
                            m="auto"
                            
                        ></View>
                    </View></View> : <></>}
               </View>
            </Column>
            <Column p="1rem">
                <Row>
                    <Text as="h3" m="0px" fontWeight="bold">{title}</Text>
                </Row>
                {/* <Row>
                    <Text as="h4" m="0px" fontWeight="400" fontStyle="italic">{subtitle}
                    </Text>
                </Row> */}
                <Row>
                    <Text as="p" m="0px" mt="1rem">
                   {content}
                    </Text>
                </Row>
                <Row pt='1rem'>
                    <View cursor="pointer" as="img" src={Spotify} width="20" height="20" onClick={() => {window.open(playlist , "_blank")}}></View>
                    <Text pl=".3rem" cursor='pointer' onClick={() => {window.open(playlist , "_blank")}}> Ecouter la playlist</Text>
                </Row>
            </Column>
        </Row>
        
      
        <GreenHr />
        </Column>
    )

})




export default WebRadioStation