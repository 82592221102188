import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Row , Text, Column, View } from "../ui";
import SearchResultCard from "../components/SearchResultCard";
import {v4 as uuidv4} from "uuid"
import Spinner from "react-spinner-material";
import { backgroundColor } from "styled-system";

const displayLoading = () => {
    let feed : any = [] ;
    for(let i = 0 ; i < 8 ; i++){
        feed.push(
            <View mb="30px" width='100%' height='200px' style={{backgroundColor : "whitesmoke"}}></View>
        )
    }
    return feed;
}


const SearchResults =() => {
    const [result , setResult] = useState<any>(null)
   const [hiddenRes , setHiddenRes] = useState<number>(0)
    let { word} = useParams();

    useEffect(() => {
        setResult(null)
        if(word.includes("pod")){
            fetch("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=2")
            .then((response) => {return response.json()})
            .then((data) => { setResult(data)})
        }
        else{
        let url = "https://wp.lechantier.radio/wp-json/wp/v2/posts/?search=" + word
        fetch(url)
        .then((response) => {return response.json()})
        .then((data) => { setResult(data)})
        }

    } , [word])

    return (
        <Row flex="1" >
        <Column width="100%" justifyContent="start" >
        <Row width="100%" color="primary" justifyContent="center"> <Text as="h1" fontWeight="bold">{`Résultats pour ${word} :`}</Text></Row>
       
            {result ?
                result.length > 0?
                result.map((res : any) => {
                    if(res.categories[0] !== 8 ){
                    return <SearchResultCard  key={uuidv4()} data={res} />
                    }else{
                        
                    }
                }) 
                : 
                <Row flex="1" justifyContent="center">
                <Text>Pas de resultats</Text>
                </Row>
                :
               displayLoading()
            }
        </Column>
        </Row>
    )
}


export default SearchResults