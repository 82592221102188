import React, { useEffect, useState, useRef, FormEvent } from "react"
import { Row, View, Column, Text, Button } from "../ui"
import HamburgerMenu from "./images/HamburgerMenu.svg"
import Logo from "./images/LogoWhite.png"
import Search from "./images/SearchIcon.png"
import useApp from "../providers/AppProvider"
import { Link, useHistory } from "react-router-dom"

const NavbarMobile = () => {

    const { setIsOpen, isOpen } = useApp()
    const [search, setSearch] = useState(false);
    const history = useHistory();
    const InputRef = useRef<HTMLInputElement>();
    const [value, setValue] = useState<string>("");


    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        setValue(e.currentTarget.value)

    }


    useEffect(() => {

    }, [])

    return (
        <Column flex="1">

            <Row flex="1" backgroundColor="secondary" justifyContent="space-between" style={{zIndex :2}}>
                <Column p="1rem">
                    <View as="img" onClick={() => { setIsOpen(!isOpen); }} src={HamburgerMenu} height="30px" width="30px"></View>
                </Column>
                <Column p="1rem" >
                    <Link to="/">
                        <View as="img" src={Logo} width="80px"></View>
                    </Link>
                </Column>
                <Column p="1rem">
                    <View as="img" width="23px" onClick={() => setSearch(!search)} src={Search} height="22px"></View>
                </Column>

            </Row>
            {search? <View onClick={() => setSearch(!search)} style={{  width:"100%" , height: "100%" ,position:"fixed" , bottom:0 , right:0 , left:0}} /> : <></>}
            {search ?
            <Row flex="1" justifyContent="center" style={{zIndex :2}}>
                <Row flex="1" backgroundColor="secondary"  justifyContent="center" alignItems={"center"} >
                   <Column alignItems="center" p="1rem">
                            <View as="input"
                                type="text"
                                placeholder="Rechercher"
                                color="primary"
                                backgroundColor="white"
                                borderStyle="none"
                                borderRadius="5px"
                                onChange={(e: FormEvent<HTMLInputElement>) => handleChange(e)}
                                value={value}
                                textAlign="right"
                                style={{ padding: '6px 10px', fontFamily: 'Barlow Condensed', fontSize: 17 }}
                            ></View>
                        </Column>
                        <Column alignItems="center">
                    <View as="img" width="23px" onClick={() => {
                        if (value !== undefined) {
                            if (value !== "") {
                                history.push(`/search/${value}`);
                            }
                        }
                        setValue("");
                        setSearch(!search)
                    }}
                        src={Search}
                        height="22px"></View>
                        </Column>

                </Row>
                </Row>
                :
                <></>

            }

        
            
    
        </Column>
    )
}

export default NavbarMobile