import React, { useState, useEffect, memo } from "react"
import { useLocation } from 'react-router-dom'
import { Text, Column, Row } from "../ui"
import NewsCard from "../components/NewsCard"
import GreenHr from "../components/GreenHr"
import SideBar from "../components/SideBar"
import { Link } from "react-router-dom"
import moment from "moment"
import Spinner from "react-spinner-material"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"
import useApp from "../providers/AppProvider"
import MenuItem from "../components/MenuItem"
import LoadingCard from "../components/LoadingCard"
import Pagination from "@material-ui/lab/Pagination"
import ScrollToTop from "../components/ScrollToTop"
import NewsCardRow from "../components/NewsCardRow"
import EmissionCardRow from "../components/EmissionCardRow"
import orderBy from 'lodash/orderBy';

const displayLoading = () => {
    let feed: any = []
    for (let i = 0; i < 10; i++) {
        feed.push(<LoadingCard />)
    }
    return feed

}

const Emissions = memo(() => {
    const params = new URLSearchParams(useLocation().search);
    const [news, setNews] = useState<any>()
    const [isLoading, setIsLoading] = useState(false);

    const stripHtml = (htmlString: string) => {
        return htmlString.replace(/<[^>]+>/g, '');
    }

    const display = () => {
        let feed: any = [];
        let sortedNews = orderBy(news, (item: any) => item.acf.ordre, ['desc']);
        sortedNews.map((article: any) => {
            console.log('ARTICLE', article)
            let parsedHTML: any = parse(article.content.rendered);
            console.log(parsedHTML)
            let srcUrl: string = "";
            for (let i = 0; i < parsedHTML.length - 1; i++) {
                if (parsedHTML[i].props) {
                    if (parsedHTML[i].props.children.type === "img") {
                        if (srcUrl === "") {
                            srcUrl = parsedHTML[i].props.children.props.src;
                        }


                    }
                }
            }
            if (srcUrl === "") {
                srcUrl = "https://www.radioking.com/api/track/cover/7701f5cd-2adb-46e6-bb3b-3982fa6e31bc";
            }
            if (parsedHTML.length) {
                feed.push(
                    <EmissionCardRow src={srcUrl}
                        title={article.title.rendered}
                        description={stripHtml(article.excerpt.rendered).replace('[&hellip;]', "...").replace(parsedHTML[1].props.children, "")}
                        slug={article.slug}
                    />
                    )
            }
        });

        return feed
    }

    useEffect(() => {
        fetch('https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=12')
                .then((res) => res.json())
                .then((data) => { setNews(data); setIsLoading(false) });
    }, []);

    return (
        <Column width="100%">
            <Column width="100%" >
                {
                    news && !isLoading
                        ?
                        display().map((article: any, index: number) => {
                            return article
                        })
                        :
                        displayLoading()
                }
            </Column>
            <Column flex="1" width="100%" justifyContent="flex-end">
            {/* <Row flex='1' justifyContent="center" p="1rem">

                {pageCount && pageNb !== null && !isLoading ?
                    <Pagination count={pageCount} page={pageNb} onChange={(event, value) => { setPageNb(value); setIsLoading(true); window.scrollTo(0, 0) }} />
                    :
                    <> </>


                }
            </Row> */}
            </Column>               
        </Column>
    )
})



export default Emissions
