import React from "react"
import { Column, View } from "../ui"



const LoadingCard = () => {
return (
    <Column flex="1" width="300px" p="1rem">
    <Column width="300px">
        <View
            height="180px"
            width="100%"
            style={{ backgroundColor : "whitesmoke" }} >
        </View>

    </Column>

    <Column pt=".5rem" width="300px" >
        <View   m="2px" as="div" height='15px' width="100%" style={{ backgroundColor : "whitesmoke"   }} ></View>
        <View   m="2px" as="div" height='15px' width="100%" style={{ backgroundColor : "whitesmoke" }} ></View>
        <View   m="2px" as="div" height='100px' width="100%" style={{ backgroundColor : "whitesmoke" }} ></View>
        <View   m="2px" as="div" height='15px' width="100%" style={{ backgroundColor : "whitesmoke" }} ></View>
    </Column>

</Column >
)
}

export default LoadingCard