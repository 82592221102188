import React, { memo } from "react"
import { Column, Row } from "../ui"
import Player from "./Player"
import WidgetTitres from "./WidgetTitres"

type props = {
    children : React.ReactNode;
}

const Display = memo<props>(({children}) => {

    return (
        <Column>
         <Row backgroundColor="primary" pl="8rem" style={{position : "sticky" , top : 0 ,zIndex : 4}} pr="8rem">
        <Player />
        <WidgetTitres />
        </Row>
        <Row width="100%" height="100%">
            {children}
        </Row>
        
        </Column>
    )
})





export default Display