import React from "react"
import { Text, Column, Row } from "../ui"
import { Link } from "react-router-dom"

const Error = () => {
    return (
        <Row flex='1' justifyContent="center">

        <Column p="1rem">

            <Text color="primary" as="h2">La page demandée n'a pas pu être trouvée.</Text>
            <Link style={{ color: "black" }} to="/">
                <Text>
                    Retourner à l'accueil
                             </Text>
            </Link>

        </Column>
         </Row >
    )
}

export default Error