import React, { useState, useEffect, memo } from "react"
import { Row, Text, Column, View } from "../ui"
import MenuItem from "./MenuItem";

type props = {
    showEmissions: () => void,
    showProgrammation: () => void,
    selected: string
}


const ProgrammeMenu = memo<props>(({ showEmissions, showProgrammation, selected }) => {
    return (
        <Row height="60px">
            <MenuItem onClick={showProgrammation} title="Grille des programmes" type={selected == "programmation" ? "active" : ''} />
            <MenuItem onClick={showEmissions} title="Emissions et podcasts" type={selected == "emissions" ? "active" : ''} />
        </Row>
    );
})
export default ProgrammeMenu