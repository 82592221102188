import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "html-react-parser";
import { Column } from "../ui";

const Pub = () => {
    const [post, setPost ] = useState<any>(null);
    const [link, setLink ] = useState<string|null>(null);
    const [imageUrl, setImageUrl ] = useState<string|null>(null);

    const fecthPost = async () => {
        const wpPostUrl = `https://wp.lechantier.radio/wp-json/wp/v2/posts/6754`;
        const response = await axios.get(wpPostUrl);
        let postContent = response.data.content.rendered;
        setPost(parse(postContent));
    }

    const setAdData = () => {
        for(let i = 0 ; i < post.length-1 ; i++){
            if(post[i].props && post[i].props.children){
                if(typeof (post[i].props.children) == "object") {
                    const imageLinkEl = post[i].props.children[0];
                    if (imageLinkEl.props && imageLinkEl.props.children && imageLinkEl.props.children.type === "img") {
                        setImageUrl(imageLinkEl.props.children.props.src);
                        setLink(imageLinkEl.props.href);
                    }
                }
            }
        }
    }
    

    useEffect(() => {  
        fecthPost();   
    } , []);

    useEffect(() => {
        if (post) {
            setAdData();
        }
    }, [post])

    return(
        post && link && imageUrl
        ?
        <Column width="100%" height="auto" justifyContent="flex-start" alignItems='flex-start'>
            <a href={link} target="_blank">
                <img src={imageUrl} height="100%" width="100%" />
            </a>
        </Column>
        :
        <></>
    );
};

export default Pub;