import React, { memo, useEffect, useState } from "react"
import ContentPage from './ContentPage';

const About = memo(() => {
    return(
        <ContentPage postId={104} />
    )
});


export default About;