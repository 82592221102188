import React, { memo, useState, useEffect } from "react"
import { Row, Text, Column, View } from "../ui"
import GreenHr from "./GreenHr"
import EpisodeClickCard from "./EpisodeClickCard"
import PodcastEpisodeCard from "./PodcastEpisodeCard"
import { v4 as uuidv4 } from "uuid"
import PodcastMenu from "./PodcastMenu"
import { Link } from "react-router-dom"

type props = {
    image?: string,
    title: string,
    subtitle?: string,
    content?: string,
    episodes?: any,
    noDetail?: boolean,
    id?: number,


}

const stripHtml = (htmlString: string) => {
    return htmlString.replace(/<[^>]+>/g, '');
}

let feed;
const PodcastCardLarge = memo<props>(({ image, title, subtitle, content, episodes, noDetail, id }) => {
    const [isClicked, setIsClicked] = useState(false);


    useEffect(() => {
        !noDetail ? setIsClicked(true) : setIsClicked(false);
    }, [])
    return (

        <Row width="100%">
            <Column>
                {/* <Row p="1rem" width="100%">
                    {
                        noDetail
                            ?
                            <Link style={{ textDecoration: "none", color: "black" }} to={"/podcasts/" + id}>
                                <Column p="1rem">
                                    <View
                                        as="img"
                                        src={image}
                                        width="300px"
                                        height="300px"

                                    ></View>
                                </Column>
                            </Link>
                            :
                            
                                <View
                                    as="div"
                                    width="100%"
                                    height="200px"
                                    
                                >
                                    <View as="div" width="100%" height="200px" style={{backgroundImage : `url(${image})`}}>

                                    </View>
                                </View>
                            
                    }
                    <Column p="1rem">
                        {
                            noDetail
                                ?
                                <Link style={{ textDecoration: "none", color: "black" }} to={"/podcasts/" + id}>
                                    <Row>
                                        <Text as="h3" m="0px">{title}</Text>
                                    </Row>
                                </Link>
                                :
                                <Row>
                                    <Text as="h3" m="0px">{title}</Text>
                                </Row>
                        }
                        <Row>
                            <Text as="h4" m="0px" fontStyle="italic">
                                {subtitle}
                            </Text>
                        </Row>
                        <Row style={{ maxHeight: "200px" }}>
                            {noDetail ?
                                <Text as="p" m="0px" mt="1rem">
                                    {
                                        content?.substr(0, 200)
                                    }
                        ...
                    </Text> :
                                <Text>{content} </Text>
                            }


                        </Row>
                        <Row>
                            {noDetail ?
                                <Column pt="1rem">
                                    <EpisodeClickCard array={episodes} podcast={title} />
                                </Column>
                                :
                                <></>
                            }
                        </Row>
                    </Column>
                </Row> */}

                {noDetail ?
                    <Row width="100%">
                        <Link style={{ textDecoration: "none", color: "black" }} to={"/podcasts/" + id}>
                            <Column p="1rem">
                                <View
                                    as="img"
                                    src={image}
                                    width="300px"
                                    height="300px"

                                ></View>
                            </Column>
                        </Link>
                        <Column p="1rem">
                            <Link style={{ textDecoration: "none", color: "black" }} to={"/podcasts/" + id}>
                                <Row>
                                    <Text as="h3"  m="0px">{title.toUpperCase()}</Text>
                                </Row>
                            </Link>
                            {/* <Row>
                                <Text as="h4" m="0px" fontStyle="italic">
                                    {subtitle}
                                </Text>
                            </Row> */}
                            <Row style={{ maxHeight: "200px" }}>

                                <Text as="p" m="0px" mt="1rem">
                                    {
                                        content?.substr(0, 200)
                                    }
                        ...
                    </Text>
                            </Row>
                            <Row>
                                <Column pt="1rem">
                                    <EpisodeClickCard array={episodes} podcast={title} />
                                </Column>
                            </Row>
                        </Column>

                    </Row>

                    :
                    <Row width="100%">
                       <Column width="100%">
                           <Text as="h2" style={{color : 'black'}}>{title.toUpperCase()}</Text>
                           {/* <Text as="h4" m="0px" fontStyle="italic">
                                    {subtitle}
                                </Text> */}
                           
                               
                                    <View as="div" width="100%" height="700px" style={{ backgroundImage: `url(${image})` , backgroundSize : "cover", backgroundPosition : "center" }}>
                                    </View>
                                    <Row flex="1" pt='1rem'>
                                    <Text>{content}</Text>
                                    </Row>
                                
                                </Column>
                            

                        

                    </Row>


                }


                {isClicked ? <Column><Text as="h2" fontWeight="bold" color="black">EPISODES</Text><GreenHr /><PodcastMenu nbSaisons={4} /></Column> : <></>}

                {isClicked ? episodes?.map((ep: any) => {
                    let episodenumber = ep.title.replace(/[^0-9]/g, "");

                    return <PodcastEpisodeCard
                        image={ep.thumbnail}
                        episode={episodenumber}
                        duration={ep.enclosure.duration}
                        episodeLink={ep.enclosure.link}
                        episodeTitle={ep.title}
                        podcastName={title}
                        key={uuidv4()}
                        description={stripHtml(ep.description)}
                    />
                }) : <></>}
                <GreenHr />
            </Column>
        </Row>
    )

})




export default PodcastCardLarge