import React, { useEffect, useState } from "react"
import { Row, Column ,Text, View } from "../ui"
import ProgrammeMenu from "../components/ProgrammeMenu"
import ProgrammeSearch from "../components/ProgrammeSearch"
import EmissionsMobile from "./EmissionsMobile"
import ProgrammationMobile from "./ProgrammationMobile"

const ProgrammeMobile = () => {
    const [selectedComponent, setSelectedComponent] = useState<any>(<ProgrammationMobile />);
    const [selectedItem, setSelectedItem] = useState<string>('programmation');

    useEffect(() => {
        console.log('SELECTED ITEM CHANGED', selectedItem);
    }, [selectedItem]);

    return (
        <Row justifyContent="center" flex="1" >
            <Column justifyContent="start">
                <ProgrammeMenu 
                    showEmissions={() => { setSelectedComponent(<EmissionsMobile />); setSelectedItem('emissions'); } } 
                    showProgrammation={() => { setSelectedComponent(<ProgrammationMobile />); setSelectedItem('programmation'); } } 
                    selected={selectedItem}
                />
                {selectedComponent}
            </Column>
        </Row>
    )
}

export default ProgrammeMobile