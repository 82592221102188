import React, { useEffect, useState } from "react"
import { Text, Column, Row } from "../ui"
import { useParams, useHistory } from "react-router-dom"
import PodcastCardLarge from "./PodcastCardLarge";
import { isUndefined, isNullOrUndefined } from "util";
import PodcastEpisodeCard from "./PodcastEpisodeCard";
import Spinner from "react-spinner-material";
import LoadingCardPodcast from "./LoadingCardPodcast";
const PodcastDisplay = () => {
    const [podcast, setPodcast] = useState<any>(null);
    let { id } = useParams()
    let history = useHistory();

    const stripHtml = (s: string) => {
        return s.replace(/(<([^>]+)>)/ig, "")
    };


    useEffect(() => {
        const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
        let url = "https://wp.lechantier.radio/wp-json/wp/v2/posts/" + id;
        fetch(url)
            .then((response) => { return response.json() })
            .then((data) => {
                if (data.title) {
                    fetch(ApiString + stripHtml(data.content.rendered))
                        .then((response) => { return response.json() })
                        .then((data) => { setPodcast(data); })
                } else {
                    history.push('/error')
                }

            })


    }, [])

    return (
        <Row flex="1" width="100%">
            <Column width="100%">
                <Row >
                    {
                        podcast
                            ?
                            <PodcastCardLarge noDetail={false} title={podcast.feed.title} image={podcast.feed.image} content={podcast.feed.description} episodes={podcast.items} />
                            :
                            <LoadingCardPodcast />}
                </Row>
            </Column>
        </Row>

    )

}

export default PodcastDisplay 