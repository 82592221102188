import React, { memo, useState, useEffect } from "react"
import { Column, View, Text, Row } from "../ui"
import Playbtn from "./images/PLAY.png"
import useApp from "../providers/AppProvider"
import { title } from "process"
import Spotify from "./images/SpotifyGrey.png"






type props = {
    image: string,
    episodeTitle: string,
    description: string,
    episodeLink: string,
    podcastName : string,
    playlist : string
    

}

const WebradioCard = memo<props>(({ image, episodeTitle,podcastName, episodeLink , description , playlist }) => {
    const [hovered, setHovered] = useState(false);
    const [hoveredId, setHoveredId] = useState(Number);
    const {setPlayerLink ,setPlayerArtist , setPlayerImage , setPlayerTitle ,setPlayerWebRadio} = useApp();

    const trimString = (text : string) => {
        let sentences = text.split("[.!?\\-]")
        return sentences[0] + sentences[1]
    }

    const TimeFormat = (time: number) => {

        // Hours, minutes and seconds
        let hrs = ~~(time / 3600);
        let mins = ~~((time % 3600) / 60);
        let secs = ~~time % 60;
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    const mouseEnter = (id: number) => {
        setHovered(true);
        setHoveredId(id)

    }

    const mouseLeave = () => {
        setHovered(false);
        setHoveredId(0)
    }

    useEffect(() => {
       
    },[])
    return (
    <Column  flex="1" width="300px" p="1rem">
            <Column
                ml="auto"
                mr="auto"
                height="290px"
                width="290px"
                style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
                onMouseEnter={() => mouseEnter(1)}
                onMouseLeave={() => mouseLeave()}
                onClick={() => {setPlayerLink(episodeLink) ; setPlayerArtist(podcastName) ; setPlayerImage(image) ; setPlayerTitle(episodeTitle) ; setPlayerWebRadio(title)}}
                cursor="pointer"
            >
                {hovered && hoveredId === 1 ? <View
                    style={{ backgroundColor: "rgba(0,0,0,.4)", transition: "ease-in .1s" }}
                    width="290px"
                    height="290px"
                    position="relative"
                >
                    <View
                        style={{ transition: "ease-in .1s" }}
                        as="div"
                        height="30px"
                        width="30px"
                        m="auto"
                        position="absolute"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                    >
                        <View
                            as="img"
                            src={Playbtn}
                            height="30px"
                            width="30px"
                            m="auto"
                            
                        ></View>
                    </View>
                </View>
                    :
                    <View></View>}
            </Column>

            <Column p=".5rem" flex="1" >
                <Text m=".5px" as="h2" fontSize="2em" fontWeight="bold">{podcastName}</Text>
                <Text>{description}</Text>
                <Row pt='1rem'>
                    <View cursor="pointer" as="img" src={Spotify} width="20" height="20" onClick={() => {window.open(playlist , "_blank")}}></View>
                    <Text pl=".3rem" cursor='pointer' onClick={() => {window.open(playlist , "_blank")}}> Ecouter la playlist</Text>
                </Row>
                
            </Column>

        </Column>
    )

})



export default WebradioCard