import React, { useEffect, useState } from "react"
import { Row, Column ,Text, View } from "../ui"
import ProgrammeCard from "../components/ProgrammeCard"
import moment, { Moment } from "moment"
import {v4 as uuidv4} from "uuid"
import Spinner from "react-spinner-material"
import ProgrammeSearch from "../components/ProgrammeSearch"



const Programmation = () => {
    const [events , setEvents] = useState<any>();
    const [loading , setLoading] = useState<boolean>(true);

    const decodeHtml = (html: string) => {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

    const stripHtml = (htmlString : string) =>{
        return htmlString.replace(/<[^>]+>/g, '');
      }

    useEffect(() => {
        setLoading(true);
        fetch("https://wp.lechantier.radio/wp-json/tribe/events/v1/events?per_page=1000000000")
        .then((response) => {return response.json()})
        .then((data) => {
            console.log(data.events);
            let sortedEvents = data.events.sort();
            setEvents(data.events)
            setLoading(false);
        })
    } ,[])

    const fetchEvents = (date: string) => {
        console.log('fetchEvents', date);
        setLoading(true);
        let url = `https://wp.lechantier.radio/wp-json/tribe/events/v1/events?per_page=1000000000`;
        if (date) {
            url = `https://wp.lechantier.radio/wp-json/tribe/events/v1/events?per_page=1000000000&start_date=${date}&end_date=${date}`;
        }
        fetch(url)
        .then((response) => {return response.json()})
        .then((data) => {
            console.log(data.events);
            let sortedEvents = data.events.sort();
            setEvents(data.events)
            setLoading(false);
        })
    }


    const timeToString = (date : Moment) => {
        let hours = date.hours();
        let minutes = date.minutes();
        let minutesDisplay = "";
        if(minutes < 10 ){
         minutesDisplay = "0"+minutes;
        }
        else {
            minutesDisplay = String(minutes)
        }
        if (hours < 1) {
            return minutesDisplay+"'"
        }
        return hours+"H"+minutesDisplay;
    }

    const minutesToString = (minutes: number) => {
        if (minutes < 60) {
            return minutes + "'";
        }
        let hours = minutes / 60;
        let min = minutes % 60;
        let minutesDisplay = "";
        if(min < 10 ){
            minutesDisplay = "0" + min;
        }
        return hours + "H" + minutesDisplay;
    }

    return (
       <Column justifyContent="flex-start" alignItems="flex-start" flex="1" >
            <ProgrammeSearch onDateChanged={(date) => fetchEvents(date)} />
            {
            events && !loading
            ? 
            events.map((event :any) => {
                let dateStart = moment(event.start_date);
                let dateEnd = moment(event.end_date);
                let content = stripHtml(event.description).split("|");
                let podcast = event.venue ? event.venue.venue : "LE CHANTIER";
                let guests = event.organizer.map((org:any) => {
                    return org.organizer;
                });
                let description = stripHtml(content[0]);
                let link = event.website;
                // let duration = dateEnd.diff(dateStart);
                let duration = moment.duration(dateEnd.diff(dateStart));
                var minutes = duration.asMinutes();

                return <ProgrammeCard 
                        title={decodeHtml(event.title)}
                        time={timeToString(dateStart)}
                        guests={decodeHtml(guests.join(', '))}
                        podcast={decodeHtml(podcast)}
                        description={decodeHtml(stripHtml(description))}
                        duration={minutesToString(minutes)}
                        link={link}
                        key={uuidv4()}
                />} 
            ) 
           :
           <Row flex="1" p="3rem" justifyContent="center"><Spinner radius={20} color="#6C00FF" visible={true} stroke={4} /></Row>
           }
           </Column>
    )
}



export default Programmation