import React, { useEffect, useState } from "react"
import {Text, Column, Row} from "../ui"
import { useParams, useHistory } from "react-router-dom"
import Spinner from "react-spinner-material";
import PodcastCard from "./PodcastCard";
import PodcastCardDetail from "./PodcastCardDetail";
import {v4 as uuidv4} from "uuid"
import LoadingCard from "./LoadingCard";

const PodcastDisplayMobile = () => {
    let feed: any;
const [podcast , setPodcast ] = useState<any>(null);
const [episode , setEpisode] = useState<any>([])
let {id} = useParams()
let history = useHistory();

const stripHtml = (s : string) => {
    return s.replace(/(<([^>]+)>)/ig,"")
};


const displayLoading = (count : number)=> {
    let feed : any = []
    for(let i = 0 ; i < 10 ; i++){
        feed.push(<LoadingCard />)
    }
    return feed.slice(0, count)
  
  }
  
useEffect(() => {
    const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
    let url = "https://wp.lechantier.radio/wp-json/wp/v2/posts/"+id;
   fetch(url)
   .then((response) => { return response.json()})
   .then((data) => {
       if(data.title){
       fetch(ApiString+stripHtml(data.content.rendered))
       .then((response) => {return response.json()})
       .then((data) => {setPodcast(data.feed) ; setEpisode(data.items) ; })
       }else {
           history.push('/error')
       }
   })
    
    
    } , [])
    
return (
     <Row flex="1">
         
        <Column>
        {podcast? <PodcastCardDetail 
        episodeTitle={podcast.title}
        podcastName={"Le Chantier"}
        description={podcast.description}
        image={podcast.image}

        
        
        /> : <></>}
         {podcast? 
         feed = episode.map((ep : any) => {
            let episodenumber = ep.title.replace(/[^0-9]/g, "");
             return (
                 <PodcastCard
                 episodeLink={ep.enclosure.link}
                 podcastName={podcast.title}
                 episodeTitle={ep.title}
                 episode={episodenumber}
                 duration={ep.enclosure.duration}
                 image={ep.thumbnail}
                 description={stripHtml(ep.description) }
                key={uuidv4()}
                 
                 />
             )

         })
          : 
         displayLoading(6)}
         </Column>
        

     </Row>
    
)

}

export default PodcastDisplayMobile