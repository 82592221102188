import React, { useEffect, useState } from "react"
import GreenHr from "../components/GreenHr"
import PodcastCardLarge from "../components/PodcastCardLarge"
import { Column, Text, Row } from "../ui"
import PodcastEpisodeCard from "../components/PodcastEpisodeCard"
import SideBar from "../components/SideBar"
import PodcastMenu from "../components/PodcastMenu"
import PodcastCard from "../components/PodcastCard"
import moment from "moment"
import Spinner from "react-spinner-material"
import {v4 as uuidv4} from "uuid"
import { Link } from "react-router-dom"
import NewsCard from "../components/NewsCard"
import LoadingCard from "../components/LoadingCard"
import PodcastNewsCard from "../components/PodcastNewsCard"

const PodcastMobile = () => {
   const [episodes, setEpisodes] = useState<any>();
    const [podcast, setPodcast] = useState<any>([]);
    const [condition , setCondition] = useState(false);

let feed;
let allpod; 
let allEp;
    
    const stripHtml = (htmlString : string) =>{
        return htmlString.replace(/<[^>]+>/g, '');
      }


      const displayLoading = (count : number)=> {
        let feed : any = []
        for(let i = 0 ; i < 10 ; i++){
            feed.push(<LoadingCard />)
        }
        return feed.slice(0, count)
      
      }

    useEffect(() => {
        const ApiString = "https://api.rss2json.com/v1/api.json?api_key=yisuboxmkz29zeunqvqhee8ypqfpqxiyrrwz02ue&count=1000&rss_url="
        
       
        fetch(("https://wp.lechantier.radio/wp-json/wp/v2/posts?categories=2"))
        .then((response) => {return response.json()})
        .then((data) => {
            let allPodcasts : any =  [];
            data.map((pod : any ) => {
                allPodcasts.push({id : pod.id  , link : stripHtml(pod.content.rendered)});
            }) 
            let tmp : Array<any> = [];
            // console.log(allPodcasts);
              allPodcasts.map(  (pod : any ,index : number) => {
                 fetch(ApiString+pod.link).then((response) => {return response.json()}).then((data) => {tmp[index] = {id : pod.id ,feed : data}} )
                 .then(() => {
                     if(tmp.length === allPodcasts.length)
                     {
                         setCondition(true);
                        //  console.log(tmp)
                         setPodcast( tmp)
                    }
                })
            })
            
           
        })

    }, [])




    return (
            <Column >
                <Text as="h2" m="1.5rem" fontWeight="bold" color="primary">PODCASTS</Text>
                <GreenHr />

                {/* {podcast ? <PodcastCardLarge title={podcast.title} image={podcast.image} content={podcast.description} subtitle={podcast.author} /> : <p>Loading</p>} */}

                {/*<PodcastCardLarge
                    title="Cartels"
                    subtitle="De l’art, un mot, un face à face !"
                    content="Chaque semaine, retrouvez Rémy et Martin dans un podcast
                     dédié aux mécaniques de la construction de l’information. Cette émission va vous
                      donner tout le biscuit nécessaire pour comprendre
                       les subtilités de la fabrique de l’actualité."
                    image="https://image.ausha.co/n50LCghpHkFu97rgFqfSvnUmeVTDgox5O9XNvS2b_1400x1400.jpeg?t=1586972190"
                    episodes={["Episode 8", "Episode 9", "Episode 10"]}

                />*/}
                {/* <PodcastMenu nbSaisons={4} /> */}
                <Column flex="1" justifyContent="center">
                   
                {condition? allEp = podcast.map((pod : any) => {
                    console.log('PODCAST MOBILE', pod)
                    // return (<Link style={{textDecoration: "none" , color: "black"}} to={`/podcasts/${pod.id}`}>
                    //     <NewsCard
                    //         title={pod.feed.feed.title}
                    //         src={pod.feed.feed.image}
                    //         description = {pod.feed.feed.description} 
                    //         podcastTitle={"Le Chantier"}  
                    //         key={uuidv4()}
                    //     />
                    // </Link>)
                    return (
                        <PodcastNewsCard
                            title={pod.feed.feed.title}
                            src={pod.feed.feed.image}
                            description = {pod.feed.feed.description} 
                            podcastTitle={"Le Chantier"}  
                            key={uuidv4()}
                            podcastId={pod.id}
                        />
                    )
                     
                 }) : displayLoading(5)}
                </Column>



            

            </Column>
            
       
    )

}

export default PodcastMobile 