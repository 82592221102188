import React, { memo, useEffect } from "react";
import { Column, Row } from "../ui";
import Player from "./Player"
import useApp from "../providers/AppProvider";
import NavbarMobile from "./NavbarMobile";
import MobileMenu from "./MobileMenu";
import PlayerMobile from "./PlayerMobile";


type Props = {
  children: React.ReactNode ;
  
}

const Layout = memo<Props>(({ children }) => {
  const {isOpen ,setIsOpen } = useApp();
  useEffect(() => {},[])
  return (
    <Column >
    {isOpen?<Row flex="1"> <Row flex="1" onClick={() => {setIsOpen(!isOpen)}} style={{position:"fixed" , bottom : 0 , right :0 , left : 0 ,top:0, zIndex:2, backgroundColor : "rgba(0,0,0,.2)", overflow:"hidden"}}></Row><MobileMenu /></Row> : <></>}
    <Row flex="1" style={{position : "fixed" ,top:0 , right:0,left:0 ,zIndex: 1 }}>
    <NavbarMobile />
    </Row>
      
     
         <Row flex="1" justifyContent="center"><Column p="1rem" mt="20%" mb="20%" style={{ width: '100%' }}>{children}</Column></Row> 
      
      {/* <Footer /> */}
        
        <Row flex="1" backgroundColor="primary" style={{position : "fixed" ,bottom:0 , right:0,left:0 ,zIndex: 1 }} >
        <PlayerMobile isMobile="yes" />
        </Row>
        
      
    </Column>
  );
});

export default Layout;
