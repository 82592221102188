import React, { memo, useState } from "react"
import { Link } from "react-router-dom";
import { Column, View, Text, Row } from "../ui"
import { v4 as uuidv4 } from "uuid"
import VioletLink from "../ui/VioletLink";
import TitleCardLink from "../ui/TitleCardLink";


const decodeHtml = (html : string) =>  {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

type props = {
    src: string,
    title: string,
    description: string,
    slug?: string
}

const EmissionCardMobile = memo<props>(({ src, title, description, slug }) => {
    const [hovered , setHovered ] = useState(false)
    const [hoverId , setHoverId] = useState(0);

    return (
        <Column flex="1">
            <Column pt="0.5rem" width="100%" >
                <Link key={uuidv4()} style={{ textDecoration: "none", color: "#000" }} to={"/emissions/" + slug}>
                    <View
                        // ml="auto"
                        // mr="auto"
                        height="200px"
                        width={"320px"}
                        style={{ backgroundImage: `url(${src})`, backgroundSize: "cover" , backgroundPosition : "center", backgroundRepeat: 'no-repeat' }} >
                    </View>
                </Link>
            </Column >

            <Column width="100%" justifyContent="flex-start">
                <TitleCardLink to={"/emissions/" + slug}>
                    {decodeHtml(title)}
                </TitleCardLink>
                <Text>{decodeHtml(description)}</Text>
                <VioletLink to={"/emissions/" + slug}>
                    Lire la suite
                </VioletLink>
            </Column>
        </Column >
    )

})



export default EmissionCardMobile